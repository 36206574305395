import { jwtDecode } from "jwt-decode";
import { toast, Slide } from "react-toastify";
import parse from "html-react-parser";

const sToast = (msg) => {
  toast.success(msg, {
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    position: "top-center",
    transition: Slide,
  });
};

const eToast = (msg) => {
  toast.error(msg, {
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    position: "top-center",
    transition: Slide,
  });
};

function formatDate(dateString, time = true) {
  const date = new Date(dateString);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  if (!time) {
    return `${day} ${month}, ${year}`;
  }

  return `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
}

function formatMoney(amount, currency = "NGN", locale = "en-NG") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
}

const HTMLParser = (htmlString) => {
  return parse(htmlString);
};

const isTokenValid = (token) => {
  try {
    if (!token) return false;

    const decodedToken = jwtDecode(token);

    const currentTime = Date.now() / 1000;

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

const Utility = {
  sToast,
  eToast,
  formatDate,
  formatMoney,
  HTMLParser,
  isTokenValid,
};

export default Utility;
