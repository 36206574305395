import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import EmergencySystemCard from "../../../Globalcomponent/EmergencySystemCard/EmergencySystemCard";
import { UserController } from "../../../Redux/controllers/UserController";
import PaginationComp from "../../../Globalcomponent/Pagination/Pagination";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import CardComp from "../../../Globalcomponent/Card/CardComp";
import Utility from "../../../utils/Utility";
import EmergencyCardComp from "./Component/EmergencyCard";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function EmergencySystem() {
  const [emergencyData, setEmergencyData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNotices, setTotalNotices] = useState(1);
  const { token } = useSelector((st) => st?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  let DOCS_PER_PAGE = 10;

  const getAllEmergencyData = async () => {
    try {
      setIsLoading(true);
      const { data } = await UserController.getEmergencyDetails(
        token,
        activePage,
        DOCS_PER_PAGE
      );
      setEmergencyData(data?.data?.emergency || []);
      setTotalPages(data?.data?.totalPages || 1);
      setTotalNotices(data?.data?.totalEmergency || 1);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllEmergencyData();
  }, [refresh, activePage]);

  const handleRefresh = async () => {
    setRefreshLoading(true); // Enable refresh loader
    try {
      await getAllEmergencyData();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false); // Disable refresh loader
    }
  };
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Emergency Notification
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        <Row className="recent-row">
          <Col className="recent-complaints">Recent Notification</Col>
          <hr />
        </Row>
        <Row>
          {isLoading ? (
            <Col
              xs={12}
              // className="d-flex justify-content-center align-items-center h-100"
            >
              {/* <Spinner variant="success" /> */}
              <Loader />
            </Col>
          ) : (
            <>
              {emergencyData?.map((item) => (
                <Col xs={12} key={item.id}>
                  <EmergencyCardComp
                    key={item?.createdAt}
                    content={item?.description}
                    date={item?.createdAt}
                    title={item.type}
                    isEmergency={true}
                    apartmentNo={
                      item?.reportedBy?.societyDetails?.apartmentNumber || "N/A"
                    }
                    apartmentName={
                      item?.reportedBy?.societyDetails?.apartmentName || "N/A"
                    }
                    sender={
                      item?.reportedBy?.firstName +
                      " " +
                      item?.reportedBy?.lastName
                    }

                    // deleted={() => setIsDeleted((prev) => !prev)}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>

        {/* pagination */}
        {totalNotices > DOCS_PER_PAGE && (
          <PaginationComp
            totalPages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </Container>
    </>
  );
}
