import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import "./UserManagement.css";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../utils/Utility";
import { getUserListAccordingToRole } from "../../../Redux/Slice/userSlice";
import ShowFamilyMembersModal from "./components/ShowFamilyMembersModal/ShowFamilyMembersModal";
import Loader from "../../../components/Loader/Loader";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "../../../utils/Excel";
import { UserController } from "../../../Redux/controllers/UserController";
import ActionButton from "../../../Globalcomponent/ActionButtons/ActionButton";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import ConfirmModal from "../../../Globalcomponent/ConfirmModal/ConfirmModal";
import BtnLoader from "../../../components/Loader/BtnLoader";
import MigrationModal from "../../SocietyAdminPages/Families/components/MigrationModal/MigrationModal";
import DeleteModal from "../../SocietyAdminPages/Families/components/DeletionModal/DeletionModal";

export default function UserManagement() {
  const dispatch = useDispatch();
  const { token, isUserListLoading } = useSelector((state) => state.user);
  const [allUsers, setAllUsers] = useState([]);
  const [showFamilyMembersModal, setShowFamilyMembersModal] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userId: "",
    flatNo: "",
  });
  const [toggleLoading, setToggleLoading] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const [selectedUserToMigrate, setSelectedUserToMigrate] = useState(null);
  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  useEffect(() => {
    getUserListAccordingToRoleFun();
  }, [refresh]);

  const getUserListAccordingToRoleFun = () => {
    dispatch(getUserListAccordingToRole({ role: "RESIDENT", token: token }))
      .then(unwrapResult)
      .then((res) => {
        setAllUsers(res?.data?.users || []);
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Get User List ! Please try again later.");
        }
      });
  };

  const handleToggleStatus = (item) => {
    setConfirmTitle(
      `Are you sure you want to ${
        item?.isActive ? "deactivate" : "activate"
      } sub admin?`
    );
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const toggleData = {
          userId: item?._id,
          isActive: !item?.isActive,
        };
        const response = await UserController.userToggleStatus(
          token,
          toggleData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const column = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sl No</span>,
        size: 50,
      },

      {
        accessorKey: "Name",
        header: "Name",
        enableResizing: true,
        size: 200,
      },
      {
        accessorKey: "Email",
        header: "Email",
        size: 50,
      },
      {
        accessorKey: "Mobile No",
        header: "Phone No",
        size: 50,
      },
      {
        accessorKey: "Apartment Name",
        header: "Apartment Name",
        size: 50,
      },

      {
        accessorKey: "Flat No",
        header: "Flat No",
        size: 50,
      },
      {
        accessorKey: "Head Of Family",
        header: "Head of Family",
        size: 50,
      },
      {
        accessorKey: "Status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "migrate",
        header: "Migrate",
        size: 50,
      },
      {
        accessorKey: "delete",
        header: "Delete",
        size: 50,
      },
      {
        accessorKey: "view",
        header: "View",
        enableResizing: true,
        size: 300,
      },
    ],
    []
  );
  const data = allUsers?.map((item, i) => ({
    srno: <span className="srnooo">{i + 1}</span>,
    Name: (
      <span>
        {item?.firstName} {item?.lastName}
      </span>
    ),
    Email: item?.email || "N/A",
    "Mobile No": item?.phoneNumber || "N/A",
    "Apartment Name": item?.societyDetails?.society?.societyName || "N/A",
    "Flat No": item?.societyDetails?.apartmentNumber || "N/A",
    "Head Of Family": item?.isHeadOfFamily ? "YES" : "NO",
    Status: (
      <div
        style={{ color: item?.isActive ? "#1a7857" : "red", fontWeight: 500 }}
      >
        {item?.isActive ? "ACTIVE" : "INACTIVE"}
      </div>
    ),
    migrate: (
      <Button
        className="migrate-btn"
        disabled={!item?.isApproved} // Disable if not approved
        onClick={() => {
          setSelectedUserToMigrate(item);
          setShowMigrationModal(true);
        }}
      >
        Migrate
      </Button>
    ),
    delete: (
      <Button
        className="delete-btn btn-danger"
        disabled={!item?.isApproved} // Disable if not approved
        onClick={() => {
          setSelectedUserToDelete(item);
          setShowDeletionModal(true);
        }}
      >
        Delete
      </Button>
    ),
    view: (
      <div className="d-flex align-items-center gap-3">
        <Button
          variant="success"
          id="showFamilyMembersButton"
          onClick={() => {
            setShowFamilyMembersModal(true);
            setUserDetails((prev) => ({
              ...prev,
              flatNo: item?.societyDetails?.apartmentNumber,
              societyId: item?.societyDetails?.society?._id,
              userId: item?._id,
            }));
          }}
        >
          All Family Members
        </Button>

        <>
          {item?.isActive ? (
            <ActionButton
              title={"De-Activate"}
              icon={<FiUserX />}
              color={"rgb(109, 117, 124)"}
              width={"8rem"}
              onClick={() => handleToggleStatus(item)}
            />
          ) : (
            <ActionButton
              title={"Activate"}
              icon={<FiUserCheck />}
              color={"#1a7857"}
              width={"8rem"}
              onClick={() => handleToggleStatus(item)}
            />
          )}
        </>
      </div>
    ),
  }));
  const handleExport = () => {
    const excelData = allUsers?.map((item, i) => ({
      Name: item?.firstName + "" + item?.lastName,
      "Mobile No": item?.phoneNumber || "N/A",
      "Apartment Name": item?.societyDetails?.society?.societyName || "N/A",
      "Flat No": item?.societyDetails?.apartmentNumber || "N/A",
      "Head Of Family": item?.isHeadOfFamily ? "YES" : "NO",
      "Active Status": item?.isActive ? "Active" : "Inactive",
    }));
    exportToExcel(excelData, "user_list");
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getUserListAccordingToRoleFun();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };

  const handleDelete = () => {
    getUserListAccordingToRoleFun();
    setShowDeletionModal(false);
  };
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  User Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        {isUserListLoading ? (
          <Loader />
        ) : (
          <>
            {allUsers?.length > 0 ? (
              <>
                <ExportButton onClick={handleExport} />
                <Row>
                  <DataTable columns={column} data={data} />
                </Row>
              </>
            ) : (
              <Row>
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-center mt-5 fw-semibold"
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </>
        )}

        <ShowFamilyMembersModal
          show={showFamilyMembersModal}
          onHide={() => {
            setShowFamilyMembersModal(false);
          }}
          userDetails={userDetails}
        />
      </Container>
      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
        loading={confirmLoading}
      />

      <MigrationModal
        show={showMigrationModal}
        onHide={() => setShowMigrationModal(false)}
        user={selectedUserToMigrate}
        onMigrate={() => {
          getUserListAccordingToRoleFun();
          setShowMigrationModal(false);
        }}
      />

      <DeleteModal
        show={showDeletionModal}
        onHide={() => setShowDeletionModal(false)}
        user={selectedUserToDelete}
        onDelete={handleDelete}
      />
    </>
  );
}
