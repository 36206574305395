import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { UserController } from "./../../../../../Redux/controllers/UserController";
import Utility from "./../../../../../utils/Utility";
import { HttpClient } from "../../../../../Redux/controllers/HttpClient";

export default function NoticeFormModal(props) {
  const { token, user } = useSelector((st) => st?.user);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [allResidents, setAllResidents] = useState(true);
  const [recipients, setRecipients] = useState([]);
  const [residentData, setResidentData] = useState([]);

  // Get resident data for the current society
  const getResidentData = async () => {
    try {
      const { data } = await HttpClient.apiCallerUsingV1(
        `user/society-members?societyId=${user?.societyDetails?.society?._id}&userType=RESIDENT`
      );
      let list = data?.data?.users?.map((item) => ({
        name: item?.firstName + " " + item?.lastName,
        _id: item?._id,
      }));
      setResidentData(list);
    } catch (error) {
      Utility.eToast("Failed to fetch resident data");
    }
  };

  // Reset form when modal opens
  useEffect(() => {
    if (props.show) {
      setAllResidents(true);
      setRecipients([]);
      setTitle("");
      setContent("");
      getResidentData();
    }
  }, [props.show]);

  // Handle publishing notice
  const handlePublish = async () => {
    try {
      if (!title) {
        return Utility.eToast("Title is Required");
      }
      if (!content) {
        return Utility.eToast("Content is Required");
      }

      setLoading(true);

      let finalData = {
        title,
        content,
        targetAudience: {},
      };

      if (allResidents) {
        // Send to all residents in the current society
        finalData.targetAudience = {
          roles: ["RESIDENT"],
          societies: [user?.societyDetails?.society?._id],
        };
      } else {
        // Send to selected specific residents
        if (recipients.length === 0) {
          return Utility.eToast("Please select recipients");
        }
        finalData.recipients = recipients;
        finalData.targetAudience = {
          roles: ["RESIDENT"],
        };
      }

      const { data } = await UserController.addNotice(finalData, token);
      Utility.sToast(data?.message);
      props.setRefresh((prev) => !prev);
      handleClose();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    // Reset all state variables
    setTitle("");
    setContent("");
    setLoading(false);
    setAllResidents(true);
    setRecipients([]);
    props.onHide();
  };

  return (
    <Container>
      <Modal size="xl" {...props} onHide={handleClose}>
        <Container style={{ minHeight: "90vh", padding: "1rem" }}>
          <Row>
            <Col xs={12} className="write-notice">
              Write a Notice
              <hr className="hr-line" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} style={{ padding: "0rem 2rem" }}>
              <Form.Check
                className="custom-checkbox check-box"
                id="allResidents"
                name="allResidents"
                label="All Residents in Society"
                checked={allResidents}
                onChange={(e) => setAllResidents(e.target.checked)}
              />

              {!allResidents && (
                <div className="my-1">
                  <Form.Label id="choose-specific">
                    Choose Specific Residents
                  </Form.Label>
                  <Multiselect
                    className="custom-multiselect"
                    options={residentData}
                    onSelect={(_, selectedItem) => {
                      let val = selectedItem?._id;
                      setRecipients((prev) => {
                        if (!prev.includes(val)) {
                          return [...prev, val];
                        }
                        return prev;
                      });
                    }}
                    onRemove={(_, removedItem) => {
                      let val = removedItem?._id;
                      setRecipients((prev) =>
                        prev.filter((item) => item !== val)
                      );
                    }}
                    displayValue="name"
                  />
                </div>
              )}
            </Col>

            <Col xs={12} sm={6} style={{ padding: "0rem 2rem" }}>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupTitle">
                  <Form.Label id="title-label">Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    id="null-placeholder"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupContent">
                  <Form.Label id="title-label">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder=""
                    id="null-placeholder1"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </Form.Group>
              </Form>

              <Row className="cross-row1">
                <Col
                  xs={6}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Button id="close-button" onClick={handleClose}>
                    Close
                  </Button>
                </Col>
                <Col xs={6}>
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <Spinner animation="border" variant="success" />
                    </div>
                  ) : (
                    <Button id="close-button1" onClick={handlePublish}>
                      Publish
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal>
    </Container>
  );
}
