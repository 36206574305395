import { combineReducers } from "redux";
import user from "./userSlice";
import society from "./societySlice";
// import legal from "./legalSlice";

const rootReducer = combineReducers({
  user: user,
  society: society,
  // legal,
});

export default rootReducer;
