import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBarMenu from "../components/SideBarMenu/SideBarMenu";
import RootRouter from "./RootRouter";
import Login from "../pages/Login/Login";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import SocietyAdminRootRouter from "./SocietyAdminRootRouter";
import "./InitialRenderer.css";

export default function InitialRenderer() {
  const { token, role, subRole } = useSelector((state) => state.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      {token ? (
        <Container fluid className="initial-renderer-container">
          <Row>
            <Col md={2} className="initial-renderer-sidebar">
              <SideBarMenu />
            </Col>
            <Col md={10} className="initial-renderer-main">
              <Container className="initial-renderer-content">
                {role === "ADMIN" ? (
                  <RootRouter />
                ) : role === "SUBADMIN" && subRole === "SOCIETY" ? (
                  <SocietyAdminRootRouter />
                ) : (
                  <PageNotFound />
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      ) : (
        <Routes>
          <Route path="/" element={<Login token={token} />} />
          <Route path="*" element={<PageNotFound page="login" />} />
        </Routes>
      )}
    </Router>
  );
}
