import React from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import ActionButton from "./../ActionButtons/ActionButton";
import "./ConfirmModal.css"
const ConfirmModal = (props) => {
  return (
    <Modal size="sm" {...props} centered backdrop={"static"}>
      <Modal.Body>
        <Row>
          <Col
            style={{
              fontFamily: "Roboto",
              fontWeight: 600,
            }}
            xs={12}
          >
            {props.title}
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center gap-5 mt-5"
          >
            <ActionButton
              title={"No"}
              color={"rgb(226, 12, 12)"}
              onClick={props.onHide}
              width={"5rem"}
              id={"no-btn"}
              disabled={props.loading}
            />
            {props.loading ? (
              <Spinner animation="border" color="#1a7857" />
            ) : (
              <ActionButton
                title={"Yes"}
                color={"#1a7857"}
                onClick={props.onConfirm}
                width={"5rem"}
                id={"yes-btn"}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;

export const OkModal = (props) => {
  return (
    <Modal size="sm" {...props} centered backdrop={"static"}>
      <Modal.Body>
        <Row>
          <Col
            style={{
              fontFamily: "Roboto",
              fontWeight: 600,
            }}
            xs={12}
          >
            {props.title}
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center gap-5 mt-5"
          >
            <ActionButton
              title={"Ok"}
              color={"#1a7857"}
              onClick={props.onConfirm}
              width={"3rem"}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
