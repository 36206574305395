import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Utility from "./Utility";

export const exportToExcel = (data, fileName) => {
  if (!data || data.length === 0) {
    return Utility.eToast("No data found to show");
  }

  // Extract headers dynamically
  const headers = Object.keys(data[0]);

  // Create a worksheet with headers and data
  const wsData = [
    headers, // Header row
    ...data.map((row) => headers.map((header) => row[header])), // Data rows
  ];
  const ws = XLSX.utils.aoa_to_sheet(wsData);

  // Set all column widths to 40
  const wscols = headers.map(() => ({ wch: 40 }));
  ws["!cols"] = wscols;

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate the XLSX file and save it using FileSaver
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    `${fileName}.xlsx`
  );
};
