import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Utility from "../../../utils/Utility";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Loader from "../../../components/Loader/Loader";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import { UserController } from "../../../Redux/controllers/UserController";
import BtnLoader from "../../../components/Loader/BtnLoader";

const backupColumns = [
  {
    accessorKey: "srno",
    header: <span className="custom-header">Sr No.</span>,
    size: 50,
  },
  {
    accessorKey: "name",
    header: "Name",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "email",
    header: "Email Id",
    size: 50,
  },
  {
    accessorKey: "role",
    header: "Role",
    size: 50,
  },
  {
    accessorKey: "deleted",
    header: "Deleted",
    size: 50,
  },
  {
    accessorKey: "members",
    header: "Members",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "apartment",
    header: "Apartment",
    size: 50,
  },
  {
    accessorKey: "restore",
    header: "Restore",
    size: 50,
  },
  {
    accessorKey: "delete",
    header: "Delete",
    size: 50,
  },
];

const migrationColumns = [
  {
    accessorKey: "srno",
    header: <span className="custom-header">Sr No.</span>,
    size: 50,
  },
  {
    accessorKey: "userName",
    header: "Family Head Name",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "migrated",
    header: "Migrated",
    size: 50,
  },
  {
    accessorKey: "members",
    header: "Members",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "fromSociety",
    header: "From Society",
    size: 100,
  },
  {
    accessorKey: "toSociety",
    header: "To Society",
    size: 100,
  },
  {
    accessorKey: "fromApartment",
    header: "From Apartment",
    size: 100,
  },
  {
    accessorKey: "toApartment",
    header: "To Apartment",
    size: 100,
  },
  {
    accessorKey: "migrationDate",
    header: "Migration Date",
    size: 100,
  },
];

const deletionColumns = [
  {
    accessorKey: "srno",
    header: <span className="custom-header">Sr No.</span>,
    size: 50,
  },
  {
    accessorKey: "name",
    header: "Name",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "email",
    header: "Email Id",
    size: 50,
  },
  {
    accessorKey: "deleted",
    header: "Deleted",
    size: 50,
  },
  {
    accessorKey: "members",
    header: "Members",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "apartment",
    header: "Apartment",
    enableResizing: true,
    size: 50,
  },
  {
    accessorKey: "deletionDate",
    header: "Deleted At",
    size: 100,
  },
];

export default function Archives() {
  const { token } = useSelector((state) => state.user);
  const [backups, setBackups] = useState([]);
  const [migrationLogs, setMigrationLogs] = useState([]);
  const [deletionLogs, setDeletionLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [refreshLoading, setRefreshLoding] = useState(false);

  // States for modals
  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const [selectedMigration, setSelectedMigration] = useState(null);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [selectedBackupId, setSelectedBackupId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);

  const fetchArchiveData = async () => {
    setIsLoading(true);
    try {
      const [backupResponse, migrationResponse, deletionResponse] =
        await Promise.all([
          UserController.getBackedUpUsers(token),
          UserController.getMigrationLogs(token),
          UserController.getDeletionLogs(token),
        ]);

      console.log(backupResponse?.data?.data?.backups);
      setBackups(backupResponse?.data?.data?.backups || []);
      setMigrationLogs(migrationResponse?.data?.data?.logs || []);
      setDeletionLogs(deletionResponse?.data?.data?.logs || []);
    } catch (err) {
      Utility.eToast("Unable to fetch archive data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setRestoreLoading(true);
      await UserController.restoreUser(selectedBackupId, token);
      Utility.sToast("User restored successfully!");
      setRestoreLoading(false);
      setShowRestoreModal(false);
      fetchArchiveData();
    } catch (err) {
      Utility.eToast("Failed to restore user.");
      setRestoreLoading(false);
      setShowRestoreModal(false);
    }
  };

  const backupData =
    backups.length > 0 &&
    backups.map((item, i) => ({
      srno: <span>{i + 1}</span>,
      name: item.firstName + " " + item.lastName || "N/A",
      email: item.email || "N/A",
      role: item.isHeadOfFamily ? "Head" : "Member" || "N/A",
      deleted: item.isWithFamily ? "With Family" : "Single" || "N/A",
      members:
        item.familyMembers?.length > 0 ? (
          <Button
            onClick={() => {
              setSelectedMigration(item);
              setShowFamilyModal(true);
            }}
            style={{ paddingLeft: "33px", paddingRight: "33px" }}
          >
            View Members
          </Button>
        ) : (
          <Button variant="secondary" disabled>
            No Family Members
          </Button>
        ),
      apartment: item.societyDetails.apartmentNumber || "N/A",
      restore: (
        <Button
          className="restore-btn"
          onClick={() => {
            setSelectedBackupId(item._id);
            setShowRestoreModal(true);
          }}
        >
          Restore
        </Button>
      ),
      delete: (
        <Button
          className="btn btn-danger"
          onClick={() => {
            setSelectedDeleteId(item._id);
            setShowDeleteModal(true);
          }}
        >
          Delete
        </Button>
      ),
    }));

  const migrationData = migrationLogs
    .sort((a, b) => new Date(b.migrationDate) - new Date(a.migrationDate))
    .map((item, i) => ({
      srno: <span>{i + 1}</span>,
      userName: item.isFamilyMigration
        ? `${item.familyHeadFirstName} ${item.familyHeadLastName}`
        : `${item.userFirstName} ${item.userLastName}` || "N/A",
      members:
        item.familyMembers?.length > 0 ? (
          <Button
            onClick={() => {
              setSelectedMigration(item);
              setShowFamilyModal(true);
            }}
            style={{ paddingLeft: "33px", paddingRight: "33px" }}
          >
            View Members
          </Button>
        ) : (
          <Button variant="secondary" disabled>
            No Family Members
          </Button>
        ),
      migrated: item.isFamilyMigration ? "With Family" : "Single" || "N/A",
      fromSociety: item.previousSocietyName || "N/A",
      toSociety: item.newSocietyName || "N/A",
      fromApartment: item.previousApartmentNumber || "N/A",
      toApartment: item.newApartmentNumber || "N/A",
      migrationDate: item.migrationDate
        ? new Date(item.migrationDate).toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "N/A",
    }));

  const deletionData = deletionLogs
    .sort((a, b) => new Date(b.deletionDate) - new Date(a.deletionDate))
    .map((item, i) => ({
      srno: <span>{i + 1}</span>,
      name: `${item.firstName} ${item.lastName}` || "N/A",
      email: item.email || "N/A",
      members:
        item.familyMembers?.length > 0 ? (
          <Button
            onClick={() => {
              setSelectedMigration(item);
              setShowFamilyModal(true);
            }}
            style={{ paddingLeft: "31px", paddingRight: "31px" }}
          >
            View Members
          </Button>
        ) : (
          <Button variant="secondary" disabled>
            No Family Members
          </Button>
        ),
      deleted: item.isFamilyDeletion ? "With Family" : "Single" || "N/A",
      apartment: item.apartmentNumber || "N/A",
      deletionDate: item.deletionDate
        ? new Date(item.deletionDate).toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "N/A",
    }));

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await UserController.deleteBackup(selectedDeleteId, token);
      Utility.sToast("User deleted permanently!");
      setDeleteLoading(false);
      setShowDeleteModal(false);
      fetchArchiveData(); // Refresh the backup list after deletion
    } catch (err) {
      Utility.eToast("Failed to delete Backup");
      setDeleteLoading(false);
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    fetchArchiveData();
    // eslint-disable-next-line
  }, []);

  const handleRefresh = async () => {
    setRefreshLoding(true);
    try {
      await fetchArchiveData();
      // Utility.sToast("Data refreshed successfully!");
    } catch (err) {
      Utility.eToast("Failed to refresh data");
    } finally {
      setRefreshLoding(false);
    }
  };

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Archives Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row style={{ marginTop: "15px" }}>
              <Row style={{ marginTop: "1rem" }}>
                <Col style={{ fontSize: "1.2rem", color: "gray" }}>
                  Deleted Families & Residents
                </Col>
                <hr />
              </Row>
              <DataTable columns={backupColumns} data={backupData} />
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Row style={{ marginTop: "1rem" }}>
                <Col style={{ fontSize: "1.2rem", color: "gray" }}>
                  Family & Resident Migration Logs
                </Col>
                <hr />
              </Row>
              <DataTable columns={migrationColumns} data={migrationData} />
            </Row>

            <Row style={{ marginTop: "15px" }}>
              <Row style={{ marginTop: "1rem" }}>
                <Col style={{ fontSize: "1.2rem", color: "gray" }}>
                  Family & Resident Deletion Logs
                </Col>
                <hr />
              </Row>
              <DataTable columns={deletionColumns} data={deletionData} />
            </Row>
          </>
        )}
      </Container>

      {/* Family Members Modal */}
      <Modal
        show={showFamilyModal}
        onHide={() => setShowFamilyModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <Modal.Title>Family Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMigration?.familyMembers?.map((member, index) => (
            <div key={member._id} className="family-list-row">
              <div>{`${index + 1}. ${member.firstName} ${
                member.lastName
              }`}</div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFamilyModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Restore Confirmation Modal */}
      <Modal
        show={showRestoreModal}
        onHide={() => setShowRestoreModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <Modal.Title>Confirm Restore</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to restore this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRestoreModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRestore}>
            {restoreLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 style={{ marginBottom: "0px", marginRight: "5px" }}>
                  Restoring{" "}
                </h6>
                <BtnLoader />
              </div>
            ) : (
              "Restore"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to permanently delete this user? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            {deleteLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6 style={{ marginBottom: "0px", marginRight: "5px" }}>
                  Deleting{" "}
                </h6>
                <BtnLoader />
              </div>
            ) : (
              "Delete Permanently"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
