import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaRegFileExcel } from "react-icons/fa";
import "./ExportButton.css";

const ExportButton = ({ onClick }) => {
  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-end">
        <Button onClick={onClick} className="export-button">
          <FaRegFileExcel />
          <span>Export To Excel</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ExportButton;
