import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Profile.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Loader from "../../../components/Loader/Loader";

export default function Profile() {
  const { user } = useSelector((state) => state.user);
  console.log(JSON.stringify(user, null, 3))
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Function to generate a placeholder avatar with the first letter of the user's name
  const getPlaceholderAvatar = () => {
    const firstLetter = user?.firstName?.charAt(0).toUpperCase();
    return (
      <div className="placeholder-avatar">
        <span className="placeholder-avatar-text">{firstLetter}</span>
      </div>
    );
  };

  return (
    <Container className="profile-container-container">
      <Container className="profile-container">
        <Row className="justify-content-center">
          <Col xs={12} className="profile-header">
            <div className="profile-avatar-wrapper">
              {isImageLoading && user?.profileImage && (
                <Loader
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minHeight: "auto",
                  }}
                  size="sm"
                  animation="border"
                  variant="success"
                />
              )}
              {user?.profileImage ? (
                <Image
                  src={user?.profileImage}
                  className={`profile-avatar ${
                    isImageLoading ? "loading" : ""
                  }`}
                  onLoad={() => setIsImageLoading(false)}
                  style={{ display: isImageLoading ? "none" : "block" }}
                />
              ) : (
                getPlaceholderAvatar()
              )}
            </div>
            <h2 className="profile-name">
              {user?.firstName} {user?.lastName}
            </h2>
            <p className="profile-email">{user?.email}</p>
          </Col>
        </Row>
        <Row className="profile-details">
          <Col xs={12}>
            <div className="profile-info">
              <span className="profile-label">Role:</span>
              <span className="profile-value">{user?.role}</span>
            </div>
            {user?.subRole && (
              <>
                <div className="profile-info">
                  <span className="profile-label">Sub Role:</span>
                  <span className="profile-value">{user?.subRole}</span>
                </div>
                <div className="profile-info">
                  <span className="profile-label">Society:</span>
                  <span className="profile-value">{user?.societyDetails?.society?.societyName}</span>
                </div>
              </>
            )}
            <div className="profile-info">
              <span className="profile-label">Phone Number:</span>
              <span className="profile-value">{user?.phoneNumber}</span>
            </div>
            <div className="profile-info">
              <span className="profile-label">Status:</span>
              <span className="profile-value">
                {user?.isActive ? "Active" : "Inactive"}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
