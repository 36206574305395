import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { scales } from "chart.js/auto";

export const SocietyStatsBarChart = ({ data, width, height }) => {
  // Prepare the data for the bar chart
  const chartData = useMemo(
    () => ({
      labels: [
        "Apartments",
        "Residents",
        "Employees",
        "Sub Admins",
        "Families",
        "Approved Users",
        "Pending Approvals",
        "Profiles Completed",
      ],
      datasets: [
        {
          label: "Total Counts",
          data: [
            data?.totalApartments || 0,
            data?.totalResidents || 0,
            data?.totalEmployees || 0,
            data?.totalSubadmins || 0,
            data?.totalFamilies || 0,
            data?.totalApprovedUsers || 0,
            data?.totalPendingApprovals || 0,
            data?.totalProfilesCompleted || 0,
          ],
          backgroundColor: ["#1a7857"],
          // borderColor: [
          //   "#FF6384",
          //   "#36A2EB",
          //   "#FFCE56",
          //   "#4BC0C0",
          //   "#9966FF",
          //   "#FF9F40",
          //   "#FF6384",
          //   "#36A2EB",
          // ],
          borderWidth: 1,
          barPercentage: 0.2,
          // categoryPercentage: 0.8,
          borderRadius: 20,
          borderSkipped: false,
        },
      ],
    }),
    [data]
  );
  const options = useMemo(
    () => ({
      scales: {
        x: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
            //   drawTicks: false,
          },
        },
        y: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            drawTicks: false,
            display: false,
          },
        },
      },
      // responsive: true,
    }),
    []
  );

  return (
    <Bar data={chartData} options={options} width={width} height={height} />
  );
};

export const GroupedBarChart = ({ data, width, height }) => {
  const chartData = useMemo(
    () => ({
      labels: ["Residents", "Employees", "Sub-admins"],
      datasets: [
        {
          label: "Pending",
          data: [
            data?.totalPendingResidents || 0,
            data?.totalPendingEmployees || 0,
            data?.totalPendingSubadmins || 0,
          ],
          backgroundColor: "#4AAB8A",
          borderColor: "#4AAB8A",
          barPercentage: 0.25,
          // categoryPercentage: 0.9,
          borderRadius: 20,
          borderSkipped: false,
        },
        {
          label: "Approved",
          data: [
            data?.totalApprovedResidents || 0,
            data?.totalApprovedEmployees || 0,
            data?.totalApprovedSubadmins || 0,
          ],
          backgroundColor: "#1a7857",
          borderColor: "#1a7857",
          // borderWidth: 1,
          barPercentage: 0.25,
          // categoryPercentage: 1,
          borderRadius: 20,
          borderSkipped: false,
        },
      ],
    }),
    [data]
  );
  const options = useMemo(
    () => ({
      scales: {
        x: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
            //   drawTicks: false,
          },
        },
        y: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            drawTicks: false,
            display: false,
          },
        },
      },
      // plugins: {
      //   legend: {
      //     display: true,
      //   },
      // },
    }),
    []
  );
  return (
    <Bar data={chartData} width={width} height={height} options={options} />
  );
};
