import React, { useMemo } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import "chart.js/auto";

export const SubAdminsPieChart = ({ data }) => {
  const chartData = useMemo(
    () => ({
      labels: Object.keys(data),
      datasets: [
        {
          data: Object.values(data),
          backgroundColor: [
            "#1A7857",
            "#359477",
            "#50B096",
            "#6BCCB6",
            "#86E8D5",
          ],
          hoverBackgroundColor: ["#50B096", "#50B096"],
        },
      ],
    }),
    [data]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
    }),
    []
  );

  return <Pie data={chartData} options={options} />;
};

export const EmployeeSubRoleDoughnutChart = ({ data, height, width }) => {
  const chartData = useMemo(
    () => ({
      labels: Object.keys(data),
      datasets: [
        {
          label: "Employees by SubRole",
          data: Object.values(data),
          backgroundColor: [
            "#1A7857",
            "#359477",
            "#50B096",
            "#6BCCB6",
            "#86E8D5",
          ],
          // hoverBackgroundColor: [
          //   "#FF6384",
          //   "#36A2EB",
          //   "#FFCE56",
          //   "#4BC0C0",
          //   "#9966FF",
          // ],
        },
      ],
    }),
    [data]
  );
  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
    }),
    []
  );

  return (
    <Doughnut
      data={chartData}
      width={width}
      height={height}
      options={options}
    />
  );
};
