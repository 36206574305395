import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Pagination,
  Row,
} from "react-bootstrap";
import CardComp from "../../../Globalcomponent/Card/CardComp";
import { CiSearch } from "react-icons/ci";
import { AiOutlinePlusCircle } from "react-icons/ai";
import NoticeFormModal from "./Components/NoticeFormModal/NoticeFormModal";
import "./NoticeManagement.css";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Loader from "./../../../components/Loader/Loader";
import PaginationComp from "../../../Globalcomponent/Pagination/Pagination";
import { CustomToggle } from "./Components/CustomFilter/CustomToggle";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Utility from "../../../utils/Utility";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function NoticeManagement() {
  let DOCS_PER_PAGE = 10;
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNotices, setTotalNotices] = useState(1);
  const [allNotices, setAllNotices] = useState([]);
  const { token } = useSelector((st) => st?.user);
  const [selectedFilter, setSelectedFilter] = useState("filter");
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  let roleDropdown = useMemo(
    () => [
      { label: "Filter", eventKey: "filter" },
      { label: "All", eventKey: "all" },
      { label: "All Residents", eventKey: "allResidents" },
      { label: "All Society", eventKey: "allSocieties" },
      { label: "All SubAdmins", eventKey: "allSubadmins" },
      { label: "All Employees", eventKey: "allEmployees" },
    ],
    []
  );

  const handleSelect = (eventKey) => {
    setSelectedFilter(eventKey);
    try {
      if (eventKey === "filter") {
        return setFilteredNotices([...allNotices]);
      }
      if (eventKey === "all") {
        let selected = allNotices?.filter((notice) => {
          return notice?.targetAudience?.allUsers;
        });
        return setFilteredNotices(selected);
      }
      if (eventKey === "allResidents") {
        let selected = allNotices?.filter((notice) => {
          return notice?.targetAudience?.roles?.some(
            (item) => item === "RESIDENT"
          );
        });
        return setFilteredNotices(selected);
      }
      if (eventKey === "allSubadmins") {
        let selected = allNotices?.filter((notice) => {
          return notice?.targetAudience?.roles?.some(
            (item) => item === "SUBADMIN"
          );
        });
        return setFilteredNotices(selected);
      }
      if (eventKey === "allEmployees") {
        let selected = allNotices?.filter((notice) => {
          return notice?.targetAudience?.roles?.some(
            (item) => item === "EMPLOYEE"
          );
        });
        return setFilteredNotices(selected);
      }
      if (eventKey === "allSocieties") {
        let selected = allNotices?.filter((notice) => {
          return notice?.targetAudience?.societies?.length > 0;
        });
        return setFilteredNotices(selected);
      }
    } catch (error) {}
  };

  const getAllNotices = async () => {
    try {
      setIsLoading(true);
      const { data } = await UserController.getNotices(
        token,
        activePage,
        DOCS_PER_PAGE
      );
      setAllNotices(data?.data?.notices || []);
      setTotalPages(data?.data?.totalPages || 1);
      setTotalNotices(data?.data?.totalNotices || 1);
      setFilteredNotices(data?.data?.notices || []);
    } catch (error) {
      if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast("Unable to get notices ! Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllNotices();
  }, [refresh, isDeleted, activePage]);
  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getAllNotices(); // Refresh the data
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };

  return (
    <>
      <Container className="mt-4" id="main-container">
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Notice Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        <Row className="mt-3">
          <Col md={4} xs={12} id="search-placeholder ">
            <span>
              <CiSearch style={{ fontSize: "22px" }} />
            </span>
            <span id="searchicon">
              <input type="text" placeholder="search..." id="searchbar" />
            </span>
          </Col>
          <Col md={3} xs={6}>
            <Dropdown onSelect={handleSelect} id="notification-dropdown">
              <Dropdown.Toggle as={CustomToggle}>
                {
                  roleDropdown?.find((item) => item.eventKey === selectedFilter)
                    ?.label
                }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {roleDropdown.map((item) => (
                  <Dropdown.Item key={item.eventKey} eventKey={item.eventKey}>
                    {item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            md={5}
            xs={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Button id="notice-button" onClick={() => setModal(true)}>
              <AiOutlinePlusCircle id="notice-icon" /> Add Notice
            </Button>
          </Col>
        </Row>

        <Row id="recent-notice">
          <Col id="recent-col">Recent Notice</Col>
          <hr />
        </Row>

        <Row>
          {isLoading ? (
            <Col
              xs={12}
              // className="d-flex justify-content-center align-items-center h-100"
            >
              {/* <Spinner variant="success" /> */}
              <Loader />
            </Col>
          ) : (
            <>
              {filteredNotices?.map((item) => (
                <Col xs={12} md={6} key={item.id}>
                  <CardComp
                    key={item?.createdAt}
                    content={item.content}
                    date={item.createdAt}
                    title={item.title}
                    noticeId={item?._id}
                    deleted={() => setIsDeleted((prev) => !prev)}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>

        {/* pagination */}
        {totalNotices > DOCS_PER_PAGE && (
          <PaginationComp
            totalPages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}

        <NoticeFormModal
          show={modal}
          onHide={() => setModal(false)}
          setRefresh={setRefresh}
        />
      </Container>
    </>
  );
}
