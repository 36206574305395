import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import "./Legal.css";

import LegalModal from "./Components/LegalModal";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
// import { getLegal } from "../../../Redux/Slice/legalSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import LegalCardComp from "./Components/LegalCard";
import Loader from "../../../components/Loader/Loader";

const Legal = () => {
  const [isPrivacySelected, setIsPrivacySelected] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [legalPPData, setLegalPPData] = useState({});
  const [legalTOSData, setLegalTOSData] = useState({});
  const { token } = useSelector((st) => st?.user);

  const handleGetLegal = async () => {
    try {
      setIsLoading(true);
      const { data } = await UserController.getLegal(token);

      let res = data?.data;
      console.log(res)
      setLegalTOSData(res?.find((e) => e.type === "TOS") || {});
      setLegalPPData(res?.find((e) => e.type === "PP") || {});
      
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleGetLegal();
  }, [anyChange]);

  return (
    <>
      <Container
        style={{ marginLeft: "-0.7rem" }}
        className="legal-tabs pt-5 pb-4"
      >
        <Row id="nav-container" style={{ marginTop: "1.5rem" }}>
          <Col id="nav-col" md={6} xs={12}>
            <Nav id="nav" variant="tabs" defaultActiveKey="privacy-policy">
              <Nav.Item>
                <Nav.Link
                  eventKey="privacy-policy"
                  onClick={() => setIsPrivacySelected(true)}
                >
                  Privacy Policy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="terms-conditions"
                  onClick={() => setIsPrivacySelected(false)}
                >
                  Terms & Conditions
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row className="mt-2">
              <Col xs={12} className="d-flex justify-content-end">
                {isPrivacySelected ? (
                  <Button
                    id="notice-button"
                    disabled={legalPPData?._id}
                    onClick={() => setShowModal(true)}
                  >
                    <AiOutlinePlusCircle id="notice-icon" /> Add New
                  </Button>
                ) : (
                  <Button
                    id="notice-button"
                    disabled={legalTOSData?._id}
                    onClick={() => setShowModal(true)}
                  >
                    <AiOutlinePlusCircle id="notice-icon" /> Add New
                  </Button>
                )}
              </Col>
            </Row>

            {isPrivacySelected ? (
              <LegalCardComp data={legalPPData} setAnyDeleted={setAnyChange} />
            ) : (
              <LegalCardComp data={legalTOSData} setAnyDeleted={setAnyChange} />
            )}

            {isPrivacySelected ? (
              <LegalModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={"New Privacy Policy"}
                type={"PP"}
                isChanged={setAnyChange}
              />
            ) : (
              <LegalModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={"New Terms & Conditions"}
                type={"TOS"}
                isChanged={setAnyChange}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Legal;
