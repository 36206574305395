import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import DataTable, {
  DefaultDataTable,
} from "../../../Globalcomponent/DataTable/DataTable";
import circlephoto from "../../../assets/dashboard/circlephoto.png";
import { UserController } from "../../../Redux/controllers/UserController";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../utils/Utility";
import "./VisitorManagement.css";
import Loader from "../../../components/Loader/Loader";
import { logout } from "../../../Redux/Slice/userSlice";
import { useNavigate } from "react-router-dom";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "../../../utils/Excel";
import ActionButton from "./../../../Globalcomponent/ActionButtons/ActionButton";
import { FaArrowRightLong } from "react-icons/fa6";
import LogModal from "./Component/LogModal";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function VisitorManagement() {
  const [visitorData, setVisitorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [imageLoading, setImageLoading] = useState({}); // Object to manage loading state for each image
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshLoading, setRefreshLoading] = useState(false);

  const [showLogModal, setShowLogModal] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const getAllVisitorData = async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getAllVisitors(token);
      setVisitorData(data?.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        Utility.eToast(error?.response?.data?.message);
        dispatch(logout());
        navigate("/");
      } else if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast("Unable to get notices ! Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllVisitorData();
  }, []);

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getAllVisitorData();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };
  
  const columns = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sl No.</span>,
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
      },
      {
        accessorKey: "apartment",
        header: "Apartment",
        size: 100,
      },
      {
        accessorKey: "flatno",
        header: "Flat  No.",
        size: 50,
      },
      {
        accessorKey: "purpose",
        header: "Purpose",
        size: 100,
      },
      {
        accessorKey: "image",
        header: "Image",
        size: 50,
      },
      {
        accessorKey: "entryinfo",
        header: "Entry Info",
        size: 100,
      },
      {
        accessorKey: "exitinfo",
        header: "Exit Info",
        size: 50,
      },
      {
        accessorKey: "vehicle",
        header: "Vehicle",
        size: 50,
      },
      {
        accessorKey: "Type",
        header: "Type",
        size: 50,
      },
    ],
    []
  );

  const getPlaceholderAvatar = (name) => {
    const firstLetter = name?.charAt(0).toUpperCase();
    return (
      <div className="vm-placeholder-avatar">
        <span className="vm-placeholder-avatar-text">{firstLetter}</span>
      </div>
    );
  };

  const data = useMemo(
    () =>
      visitorData?.map((item, i) => ({
        srno: <span className="srnooo">{i + 1}</span>,
        name: <span className="empiiid">{item?.name || "N/A"}</span>,
        apartment: item?.society?.societyName || "N/A",
        image: (
          <img
            src={item?.visitorImage}
            id="visitor-image"
            alt="visitor image"
            onClick={() => handleShow(item?.visitorImage)}
          />
        ),
        flatno: (
          <Button disabled className="v-button">
            {item?.hostResident?.societyDetails?.apartmentNumber || "N/A"}{" "}
          </Button>
        ),
        purpose: <span className="empiiid">{item.purpose} </span>,
        entryinfo: (
          <span className="empiiid">
            {item?.entryCodeGenerationTime
              ? Utility?.formatDate(item?.entryCodeGenerationTime)
              : "N/A"}
          </span>
        ),
        exitinfo: (
          <span className="empiiid">
            {item?.exitTime ? Utility?.formatDate(item?.exitTime) : "N/A"}{" "}
          </span>
        ),
        vehicle: item?.isWithVehicle ? "YES" : "NO",
        Type: (
          <div className="d-flex align-items-center gap-3">
            <span>{item?.visitType || "N/A"}</span>
            {item?.visitType === "DAILY" && (
              <ActionButton
                color={"#1a7857"}
                title={"View Logs"}
                icon={<FaArrowRightLong />}
                // width={"5rem"}
                onClick={() => {
                  setSelectedVisitor(item);
                  setShowLogModal(true);
                }}
              />
            )}
          </div>
        ),
      })),
    [visitorData, imageLoading]
  );
  const handleExport = () => {
    let excelData = visitorData?.map((item, i) => ({
      Name: item?.name || "N/A",
      "Apartment Name": item?.society?.societyName || "N/A",

      "Flat no": item?.hostResident?.societyDetails?.apartmentNumber || "N/A",
      Purpose: item.purpose,
      "Entry Info": item?.entryCodeGenerationTime
        ? Utility?.formatDate(item?.entryCodeGenerationTime)
        : "N/A",
      "Exit Info": item?.exitTime ? Utility?.formatDate(item?.exitTime) : "N/A",
      Vehicle: item?.isWithVehicle ? "YES" : "NO",
      Type: item?.visitType || "N/A",
    }));
    exportToExcel(excelData, "visitor-list");
  };
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
        <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Visitor Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        {loading ? (
          <Loader />
        ) : (
          <>
            {visitorData?.length > 0 ? (
              <>
                <ExportButton onClick={handleExport} />
                <Row>
                  <DataTable columns={columns} data={data} />
                </Row>
              </>
            ) : (
              <Row>
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-center mt-5 fw-semibold"
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>

      <Modal size="md" show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <img src={modalImage} alt="Visitor" className="img-fluid" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <LogModal
        show={showLogModal}
        onHide={() => setShowLogModal(false)}
        selectedVisitor={selectedVisitor}
      />
    </>
  );
}
