import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../../../Redux/controllers/UserController";
import Utility from "../../../../utils/Utility";
import LegalModal from "./LegalModal";
import "./LegalCard.css";
import ConfirmModal from "../../../../Globalcomponent/ConfirmModal/ConfirmModal";
import DeletePolicyModal from "./DeletePolicyModal/DeletePolicyModal";

export default function LegalCardComp({ data, setAnyDeleted }) {
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [confirmLoading, setConfirmLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteLegal = () => {
    setConfirmTitle("Are you sure to delete?");
    setConfirmAction(() => async () => {
      try {
        setConfirmLoading(true);
        const response = await UserController.deleteLegal(token, data?._id);
        Utility.sToast(response?.data?.data?.message);
        setAnyDeleted((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  // Move the conditional check outside of any functions
  if (!data?._id) {
    return (
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center mt-5"
        >
          No Record Found
        </Col>
      </Row>
    );
  }

  const truncatedContent = (content) =>
    content.length > 350 ? `${content.substring(0, 400)} ...` : content;

  return (
    <Card id="legal-card-row-row" className="mt-5">
      <Row className="mb-4">
        <Col xs={12} className="d-flex align-items-center justify-content-end">
          {Utility.formatDate(data?.createdAt)}
        </Col>
      </Row>
      <Row className="appartment-name234 mb-3">
        <Col
          xs={12}
          className="fs-5 d-flex align-items-center gap-4 apartment-small"
        >
          {data?.title}
        </Col>
      </Row>
      <Row style={{ paddingLeft: "0.3rem", marginTop: "0.5rem" }}>
        <Col id="dear">
          {data?.content ? (
            <div>
              {showMore
                ? Utility.HTMLParser(data?.content)
                : Utility.HTMLParser(truncatedContent(data?.content))}
              {data?.content.length > 350 && (
                <Button
                  variant="link"
                  onClick={() => setShowMore(!showMore)}
                  style={{ padding: 0 }}
                  className="show-more-btn"
                >
                  {showMore ? "Show less" : "Show more"}
                </Button>
              )}
            </div>
          ) : (
            "N/A"
          )}
        </Col>
      </Row>
      <Row className="nav-row d-flex justify-content-end">
        <Col md={6} xs={12} className="d-flex gap-3 justify-content-end">
          <Button
            variant="light"
            id="legal-edit-button"
            onClick={() => setShowEditModal(true)}
          >
            <CiEdit /> Edit
          </Button>
          <Button
            variant="danger"
            id="legal-delete-button"
            onClick={handleDeleteLegal}
          >
            <RiDeleteBin6Line /> Delete
          </Button>
        </Col>
      </Row>

      <LegalModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        data={data}
        edit={true}
        isChanged={setAnyDeleted}
        title={
          data?.type === "TOS"
            ? "Edit Terms & Conditions"
            : "Edit Privacy Policy"
        }
      />

      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
        loading={confirmLoading}
      />
    </Card>
  );
}
