import React, { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Utility from "../../../../utils/Utility";

const formatContent = (text) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const truncateContent = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit);
  }
  return text;
};

export default function EmergencyCardComp({
  date,
  title = "",
  content = "",
  noticeId,
  deleted,
  apartmentName,
  apartmentNo,
  sender,
  status,
}) {
  const [showMore, setShowMore] = useState(false);
  const contentLimit = 150;
  //   const { token } = useSelector((st) => st?.user);

  //   const [showConfirmModal, setShowConfirmModal] = useState(false);
  //   const [confirmTitle, setConfirmTitle] = useState("");
  //   const [confirmAction, setConfirmAction] = useState(() => {});
  //   const [confirmLoading, setConfirmLoading] = useState(false);

  //   const deleteNotice = async () => {
  //     setConfirmTitle("Are You sure to delete notification?");
  //     setConfirmAction(() => async () => {
  //       try {
  //         setConfirmLoading(true);
  //         const { data } = await UserController.deleteNotice(noticeId, token);
  //         Utility.sToast(data?.message);
  //         deleted();
  //       } catch (error) {
  //         Utility.eToast(error?.response?.data?.message || error?.message);
  //       } finally {
  //         setConfirmLoading(false);
  //         setShowConfirmModal(false);
  //       }
  //     });
  //     setShowConfirmModal(true);
  //   };

  return (
    <>
      <Card
        id="card-row-row-1"
        // className={`position-relative ${isComplaint && "complaint-row"}`}
      >
        {/* <div className="notice-delete"> */}
        {/* {!isComplaint && (
          <>
            {/* {" "}
            {!showMore && (
              <>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="danger"
                    size="md"
                    id="notice-delete-spinner"
                  /> */}
        {/* ) : ( */}
        {/* <Button
              variant="danger"
              id="close-button234"
              onClick={deleteNotice}
            >
              <RiDeleteBin6Line /> Delete
            </Button> */}
        {/* )} */}
        {/* </> */}
        {/* )} */}
        {/* </>
        )} */}
        {/* </div> */}

        <Row className="mb-4">
          <Col xs={12} md={4} className="d-flex align-items-center gap-2 my-1">
            <span className="appartment-name234">Reported By</span>
            <Button disabled className="v-button">
              {sender}
            </Button>
          </Col>
          <Col xs={12} md={4} className="d-flex align-items-center gap-2 my-1">
            <span className="appartment-name234">Apartment Name</span>
            <Button disabled className="v-button">
              {apartmentName}
            </Button>
          </Col>
          <Col xs={12} md={2} className="d-flex align-items-center gap-2 my-1">
            <span className="appartment-name234">Flat No</span>

            <Button disabled className="v-button">
              {apartmentNo}
            </Button>
          </Col>
          <Col
            xs={12}
            md={2}
            className="d-flex align-items-center justify-content-end my-1"
          >
            {Utility.formatDate(date)}
          </Col>
        </Row>
        <Row className="appartment-name234 mb-3">
          <Col
            xs={12}
            className="fs-5  d-flex align-items-center gap-4 apartment-small"
            style={{ color: "#DC3545" }}
          >
            {title}
          </Col>
        </Row>

        <Row style={{ paddingLeft: "0.3rem", marginTop: "0.5rem" }}>
          <Col id="dear">
            {showMore
              ? formatContent(content)
              : formatContent(truncateContent(content, contentLimit))}
            {!showMore && content.length > contentLimit && "..."}
          </Col>
        </Row>
        <Row>
          <Col>
            {content.length > contentLimit && (
              <Button
                variant="link"
                style={{ color: "#006944", fontWeight: "500" }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </Button>
            )}
          </Col>
        </Row>
      </Card>
      {/* <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
        loading={confirmLoading}
      /> */}
    </>
  );
}

{
  /* <Row style={{ paddingLeft: "0.3rem", marginTop: "1rem" }}>
          <Col id="dear">{content}</Col>
        </Row> */
}
{
  /* <Row style={{ paddingLeft: "0.3rem", marginTop: "1rem" }}>
          <Col id="dear">
            Thank you,
            <br /> Society Administration
          </Col>
        </Row> */
}
