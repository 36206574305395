import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
  societyAdminLogin,
  setRole,
} from "../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../utils/Utility";
import { IoEye, IoEyeOff } from "react-icons/io5";
import logo from "../../assets/dashboard/logo.png";
import "./Login.css";
import { ResetPasswordModal } from "./components/ResetPasswordModal";

export default function Login({ token }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoginLoading } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRoleSelection] = useState("system");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  const regEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

  const validation = () => {
    if (email.trim() === "") {
      setPasswordErr("");
      setEmailErr("Enter Email Id");
      return false;
    } else if (!regEmail.test(email)) {
      setPasswordErr("");
      setEmailErr("Enter a valid Email");
      return false;
    } else if (password.trim() === "") {
      setEmailErr("");
      setPasswordErr("Enter Password");
      return false;
    } else {
      setEmailErr("");
      setPasswordErr("");
      return true;
    }
  };

  const handleLogin = () => {
    console.log("role is", role);
    if (validation()) {
      const data = { email, password };
      const loginAction =
        role === "system" ? adminLogin(data) : societyAdminLogin(data);

      dispatch(loginAction)
        .then(unwrapResult)
        .then((res) => {
          // For system admin, proceed normally
          if (role === "system") {
            dispatch(
              setRole({
                role: res.user.role,
                subRole: res.user.subRole || null,
              })
            );
            Utility.sToast("Sign-in successful! Welcome back!");
            navigate("/dashboard");
            return;
          }

          console.log("resss", res);

          // For society admin, validate role and subrole
          const isValidSocietyAdmin =
            res.user.role === "SUBADMIN" && res.user.subRole === "SOCIETY";

          if (!isValidSocietyAdmin) {
            Utility.eToast("Access denied: Invalid role for Society Admin");
            return; // Don't navigate or set role if validation fails
          }

          // Only if society admin validation passes
          dispatch(
            setRole({ role: res.user.role, subRole: res.user.subRole || null })
          );
          Utility.sToast("Sign-in successful! Welcome back!");
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err?.response?.data?.message === "Invalid credentials") {
            Utility.eToast("Invalid Credentials");
          } else if (err?.message === "Network Error") {
            Utility.eToast("Network error! Please try again later.");
          } else {
            Utility.eToast("Unable to Login! Please try again later.");
          }
        });
    }
  };

  return (
    <Container fluid className="login-container">
      <div className="login-overlay"></div>
      <Row className="justify-content-center align-items-center min-vh-100 login-content">
        <Col xs={12} md={6} className="login-box">
          <Row>
            <Col className="logo-container">
              <Image src={logo} className="logo-img" />
            </Col>
          </Row>
          <Row>
            <Col className="admin-account">
              Log in to {role === "system" ? "System Admin" : "Society Admin"}{" "}
              account
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <button
                className={`toggle-btn ${role === "system" ? "selected" : ""}`}
                onClick={() => setRoleSelection("system")}
              >
                System Admin
              </button>
              <button
                className={`toggle-btn ${role === "society" ? "selected" : ""}`}
                onClick={() => setRoleSelection("society")}
              >
                Society Admin
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Email <span className="required-icon">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="abc@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="error-text">{emailErr}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Password <span className="required-icon">*</span>
                  </Form.Label>
                  <div className="password-container">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Abcd@123456"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="password-toggle"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoEyeOff size={20} />
                      ) : (
                        <IoEye size={20} />
                      )}
                    </span>
                  </div>
                  <div className="error-text">{passwordErr}</div>
                </Form.Group>
                <Row>
                  <Button
                    className="sign-in-button"
                    disabled={isLoginLoading}
                    onClick={handleLogin}
                  >
                    Sign in{" "}
                    {isLoginLoading && (
                      <Spinner size="sm" variant="light" className="ms-1" />
                    )}
                  </Button>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  {role === "society" && (
                    <Button
                      style={{ backgroundColor: "red" }}
                      className="sign-in-button"
                      onClick={() => setShowResetPasswordModal(true)}
                    >
                      Reset Password
                    </Button>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <ResetPasswordModal
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
      />
    </Container>
  );
}
