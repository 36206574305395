import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import "./AddSocietyModal.css";
import { useDispatch, useSelector } from "react-redux";
import { addSociety } from "../../../../../Redux/Slice/societySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../../../utils/Utility";

export default function AddSocietyModal(props) {
  const { isAddSocietyLoading } = useSelector((state) => state.society);
  const dispatch = useDispatch();

  const [societyCode, setSocietyCode] = useState("");
  const [societyName, setSocietyName] = useState("");
  const [address, setAddress] = useState({
    street: "",
    area: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });

  // Error states
  const [errors, setErrors] = useState({
    societyCode: "",
    societyName: "",
    zipCode: "",
  });

  const validation = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Society Code
    if (!societyCode?.trim() || societyCode?.length < 4) {
      newErrors.societyCode = "Enter Valid Society Code";
      isValid = false;
    } else {
      newErrors.societyCode = "";
    }

    // Validate Society Name
    if (!societyName?.trim() || societyName?.length < 5) {
      newErrors.societyName = "Enter Valid Society Name";
      isValid = false;
    } else {
      newErrors.societyName = "";
    }

    // Validate ZIP Code if provided

    setErrors(newErrors);
    return isValid;
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewSociety = async () => {
    if (validation()) {
      const data = {
        societyCode,
        societyName,
        address: {
          street: address.street,
          area: address.area,
          city: address.city,
          state: address.state,
          country: address.country,
          zipCode: address.zipCode,
        },
      };

      dispatch(addSociety({ data: data, token: props.token }))
        .then(unwrapResult)
        .then((res) => {
          Utility.sToast("New Society Added !");
          // Reset form
          setSocietyCode("");
          setSocietyName("");
          setAddress({
            street: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          });
          props.getSocietyFun();
          props.onHide();
        })
        .catch((err) => {
          if (err?.message === "Network Error") {
            Utility.eToast("Network error! Please try again later.");
          } else {
            Utility.eToast("Unable to Add Society ! Please try again later.");
          }
        });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#ffffff" }}
        >
          Add New Society
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label id="societyLabel">
                  Society Code <span id="errText">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Society Code"
                  value={societyCode}
                  onChange={(e) => setSocietyCode(e.target.value)}
                />
                <div id="errText">{errors.societyCode}</div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label id="societyLabel">
                  Society Name <span id="errText">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Society Name"
                  value={societyName}
                  onChange={(e) => setSocietyName(e.target.value)}
                />
                <div id="errText">{errors.societyName}</div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Street</Form.Label>
                <Form.Control
                  type="text"
                  name="street"
                  placeholder="Enter Street Address"
                  value={address.street}
                  onChange={handleAddressChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  name="area"
                  placeholder="Enter Area"
                  value={address.area}
                  onChange={handleAddressChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  placeholder="Enter Country"
                  value={address.country}
                  onChange={handleAddressChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  value={address.city}
                  onChange={handleAddressChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  placeholder="Enter State"
                  value={address.state}
                  onChange={handleAddressChange}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>ZIP Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipCode"
                  placeholder="Enter ZIP Code"
                  value={address.zipCode}
                  onChange={handleAddressChange}
                />
                <div id="errText">{errors.zipCode}</div>
              </Form.Group>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              id="close-add-society"
              onClick={() => {
                props.onHide();
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              id="add-society-button"
              disabled={isAddSocietyLoading}
              onClick={handleAddNewSociety}
              variant="success"
            >
              Add{" "}
              {isAddSocietyLoading && (
                <Spinner size="sm" variant="light" className="ms-1" />
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
