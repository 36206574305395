import { Button } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";

export function CustomToggle({ children, onClick }) {
  return (
    <Button
      id="filter-button2345"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <CiFilter id="filter-icon" /> {children}
    </Button>
  );
}
