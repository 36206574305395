import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserManagement from "../AdminWeb/Pages/UserManagement/UserManagement";
import Society from "../AdminWeb/Pages/Society/Society";
import Maintenance from "../AdminWeb/Pages/Maintenance/Maintenance";
import NoticeManagement from "../AdminWeb/Pages/NoticeManagement/NoticeManagement";
import Complaints from "../AdminWeb/Pages/Complaints/Complaints";
import EmergencySystem from "../AdminWeb/Pages/EmergencySystem/EmergencySystem";
import Employees from "../AdminWeb/Pages/Employees/Employees";
import SubAdmin from "../AdminWeb/Pages/SubAdmin/SubAdmin";
import CarParking from "../AdminWeb/Pages/CarParking/CarParking";
import VisitorManagement from "../AdminWeb/Pages/VisitorManagement/VisitorManagement";
import DashboardPage from "../AdminWeb/Pages/Dashboard/DashboardPage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Legal from "../AdminWeb/Pages/Legal/Legal";
import Profile from "../AdminWeb/Pages/Profile/Profile";
import { logout } from "../Redux/Slice/userSlice";
import Utility from "../utils/Utility";
import { OkModal } from "../Globalcomponent/ConfirmModal/ConfirmModal";

export default function RootRouter() {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});

  useEffect(() => {
    if (!Utility.isTokenValid(token)) {
      setConfirmTitle("Your session expired! Please login again.");
      setConfirmAction(() => () => {
        dispatch(logout());
        setShowConfirmModal(false);
      });
      setShowConfirmModal(true);
    }
  }, [dispatch, token]);

  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/management" element={<UserManagement />} />
        <Route path="/society" element={<Society />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/notice" element={<NoticeManagement />} />
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/emergency" element={<EmergencySystem />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/subadmin" element={<SubAdmin />} />
        <Route path="/car" element={<CarParking />} />
        <Route path="/visitor" element={<VisitorManagement />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <OkModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
      />
    </>
  );
}
