import React, { useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./DataTable.css";
import { Container } from "react-bootstrap";

const DataTable = ({
  columns,
  data,
  enableCustomPagination = false,
  rowCount,
  pagination,
  setPagination,
  enablePagination = true,
  enableGlobalFilter = true,
}) => {
  const isCustomPagination = enableCustomPagination
    ? {
        columns,
        data,
        enablePagination: enablePagination || null,
        enableGlobalFilter: enableGlobalFilter || null,
        manualPagination: enableCustomPagination || null,
        rowCount: enableCustomPagination ? rowCount : null,
        state: enableCustomPagination ? { pagination } : {},
        onPaginationChange: enableCustomPagination ? setPagination : null,
      }
    : {
        columns,
        data,
        enablePagination: enablePagination || null,
        enableGlobalFilter: enableGlobalFilter || null,
        manualPagination: enableCustomPagination || null,
        rowCount: enableCustomPagination ? rowCount : null,
        state: enableCustomPagination ? { pagination } : {},
      };

  const table = useMaterialReactTable({
    ...isCustomPagination,

    muiTableHeadCellProps: {
      sx: {
        background: "#ffffff",
        color: "black",
      },
    },
  });

  return (
    <Container className="table-container">
      <MaterialReactTable table={table} />
    </Container>
  );
};

export default DataTable;
