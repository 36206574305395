import React, { useEffect, useMemo, useState } from "react";
import "./Society.css";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import { unwrapResult } from "@reduxjs/toolkit";
import { getSociety } from "../../../Redux/Slice/societySlice";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../utils/Utility";
import { MdAddHomeWork, MdLocationOn } from "react-icons/md";
import AddSocietyModal from "./components/AddSocietyModal/AddSocietyModal";
import Loader from "../../../components/Loader/Loader";
import {
  FaRegFileExcel,
  FaRegTrashAlt,
  FaUsers,
  FaUsersSlash,
} from "react-icons/fa";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import { exportToExcel } from "../../../utils/Excel";
import { UserController } from "../../../Redux/controllers/UserController";
import ActionButton from "../../../Globalcomponent/ActionButtons/ActionButton";
import ConfirmModal from "../../../Globalcomponent/ConfirmModal/ConfirmModal";
import BtnLoader from "../../../components/Loader/BtnLoader";
import DeleteSocietyModal from "./components/DeleteSocietyModal/DeleteSocietyModal";

export default function Society() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.user);
  const { isGetSocietyLoading } = useSelector((state) => state.society);

  const [societyData, setSocietyData] = useState([]);
  const [showAddSocietyModal, setShowAddSocietyModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSociety, setSelectedSociety] = useState(null);

  useEffect(() => {
    getSocietyFun();
  }, [refresh]);

  const getSocietyFun = () => {
    dispatch(getSociety({ data: token }))
      .then(unwrapResult)
      .then((res) => {
        setSocietyData(res?.data);
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Get Society Data! Please try again later.");
        }
      });
  };

  const formatAddress = (address) => {
    if (!address) return "N/A";

    const addressParts = [
      address.street,
      address.area,
      address.city,
      address.state,
      address.country,
      address.zipCode,
    ].filter(Boolean);

    return addressParts.join(", ");
  };

  const handleDeleteSociety = (society) => {
    setSelectedSociety(society);
    setShowDeleteModal(true);
  };
  const handleToggleStatus = (item) => {
    setConfirmTitle(
      `Are you sure you want to ${item?.isActive ? "deactivate" : "activate"}?`
    );
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const toggleData = {
          societyId: item?._id,
          isActive: !item?.isActive,
        };
        const response = await UserController.societyToggleStatus(
          token,
          toggleData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const handleUnBlockUsers = (item) => {
    setConfirmTitle("Are you sure to unblock associated users?");
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const blockData = {
          societyId: item?._id,
        };
        const response = await UserController.unBlockSocietyUsers(
          token,
          blockData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const handleBlockUsers = (item) => {
    setConfirmTitle("Are you sure to block associated users?");
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const blockData = {
          societyId: item?._id,
        };
        const response = await UserController.blockSocietyUsers(
          token,
          blockData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const societyColumn = useMemo(
    () => [
      {
        accessorKey: "slNo",
        header: "Sl No",
        size: 50,
      },
      {
        accessorKey: "Society Code",
        header: "Society Code",
        size: 50,
      },
      {
        accessorKey: "Society Name",
        header: "Society Name",
        size: 100,
      },
      {
        accessorKey: "Address",
        header: "Address",
        size: 200,
      },
      {
        accessorKey: "Status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "view",
        header: "Actions ",
        size: 100,
      },
    ],
    []
  );

  const societyTableData = useMemo(
    () =>
      societyData?.map((item, i) => {
        const fullAddress = formatAddress(item?.address);

        return {
          slNo: i + 1,
          "Society Name": item?.societyName || "N/A",
          "Society Code": item?.societyCode || "N/A",
          Address: (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`address-tooltip-${item?._id}`}>
                  {fullAddress}
                </Tooltip>
              }
            >
              <div className="d-flex align-items-center">
                <MdLocationOn className="me-2" />
                <span className="text-truncate" style={{ maxWidth: "200px" }}>
                  {fullAddress}
                </span>
              </div>
            </OverlayTrigger>
          ),
          "Active Status": item?.isActive ? "Active" : "Inactive",
          Status: (
            <div
              style={{
                color: item?.isActive ? "#1a7857" : "red",
                fontWeight: 500,
              }}
            >
              {item?.isActive ? "ACTIVE" : "INACTIVE"}
            </div>
          ),
          view: (
            <div className="d-flex align-items-center gap-3">
              <FaRegTrashAlt
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                  color: "rgb(226, 12, 12)",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteSociety(item)}
              />
              <ActionButton
                title={item?.isActive ? "De-Activate" : "Activate"}
                icon={item?.isActive ? <FiUserX /> : <FiUserCheck />}
                color={item?.isActive ? "rgb(109, 117, 124)" : "#1a7857"}
                width={"8rem"}
                onClick={() => handleToggleStatus(item)}
              />
              <ActionButton
                title={
                  item?.allUsersBlocked
                    ? "Unblock Associated User"
                    : "Block Associated User"
                }
                icon={item?.allUsersBlocked ? <FaUsers /> : <FaUsersSlash />}
                color={item?.allUsersBlocked ? "#1a7857" : "rgb(109, 117, 124)"}
                width={"13rem"}
                onClick={() =>
                  item?.allUsersBlocked
                    ? handleUnBlockUsers(item)
                    : handleBlockUsers(item)
                }
              />
            </div>
          ),
        };
      }),
    [societyData]
  );

  const handleExport = () => {
    let excelData = societyTableData.map(
      ({ slNo, view, Status, ...rest }) => rest
    );
    exportToExcel(excelData, "society-list");
  };
  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getSocietyFun();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Society Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        {isGetSocietyLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col xs={12} className="d-flex justify-content-end gap-4">
                <Button
                  id="addSocietyButton"
                  onClick={() => setShowAddSocietyModal(true)}
                >
                  <MdAddHomeWork />
                  Add New Society
                </Button>
                {societyData?.length > 0 && (
                  <Button onClick={handleExport} className="export-button">
                    <FaRegFileExcel />
                    <span>Export To Excel</span>
                  </Button>
                )}
              </Col>
            </Row>

            {/* {showAddSocietyModal && ( */}
            <AddSocietyModal
              show={showAddSocietyModal}
              onHide={() => setShowAddSocietyModal(false)}
              token={token}
              getSocietyFun={getSocietyFun}
            />

            {societyData?.length > 0 ? (
              <>
                <Row>
                  <DataTable columns={societyColumn} data={societyTableData} />
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center mt-5 fw-semibold"
                  >
                    No Records Found
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>

      <ConfirmModal
        title={confirmTitle}
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={confirmAction}
        loading={confirmLoading}
      />

      <DeleteSocietyModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        societyData={societyData}
        selectedSociety={selectedSociety}
        token={token}
        onSuccess={() => {
          setRefresh((prev) => !prev);
        }}
      />
    </>
  );
}
