import React from "react";
import { Button, Container, Image, Row } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import circlephoto from "../../../assets/dashboard/circlephoto.png";
import "./CarParking.css";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
const transactioDetails = [
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
  {
    carregdno: "OD 01 0001",
    image: <Image src={circlephoto} className="circle-photo" />,
    nameofowner: "John D",
    apartmentname: "Z1 Apartment",
    flatnumber: "049",
    carandmodal: "KIA & QOO3",
    view: "...",
  },
];

export default function CarParking() {
  const column = [
    {
      accessorKey: "carregdno",
      header: "Car Reg. No.",
      size: 50,
    },
    {
      accessorKey: "image",
      header: "Image",
      size: 50,
    },

    {
      accessorKey: "nameofowner",
      header: "Name of Owner",
      size: 50,
    },
    {
      accessorKey: "apartmentname",
      header: "Apartment Name",
      size: 50,

      //   size: 50,
    },
    {
      accessorKey: "flatnumber",
      header: "Flat No.",
      size: 50,

      //   size: 50,
    },
    {
      accessorKey: "carandmodal",
      header: "Car Name & Model",
      size: 50,
    },
    {
      accessorKey: "view",
      header: "",
      size: 50,

      //   size: 50,
    },
  ];
  const data = transactioDetails.map((item, i) => ({
    carregdno: item?.carregdno || "N/A",
    image: item?.image || "N/A",
    nameofowner: item?.nameofowner || "N/A",
    apartmentname: item?.apartmentname || "N/A",
    flatnumber: item?.flatnumber || "N/A",
    carandmodal: item?.carandmodal || "N/A",
    view: item?.view || "N/A",

    carregdno: <span className="empiiid">{item.carregdno} </span>,
    nameofowner: <span className="empiiid">{item.nameofowner} </span>,
    apartmentname: (
      <Button className="apartment-button">{item.apartmentname} </Button>
    ),
    flatnumber: (
      <Button className="apartment-button">{item.flatnumber} </Button>
    ),
    carandmodal: <span className="empiiid">{item.carandmodal} </span>,
    view: <span className="empiiid2">{item.view} </span>,
  }));
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "0.5rem" }}>
          <TopBar name="Car Parking Details" />
        </Row>
        <ExportButton />
        <Row>
          <DataTable columns={column} data={data} />
        </Row>
      </Container>
    </>
  );
}
