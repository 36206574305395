import React from "react";
import Spinner from "react-bootstrap/Spinner";

function Loader(props) {
  return (
    <div
      style={
        props.style || {
          minHeight: "40vh",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center"
        }
      }
    >
      <Spinner
        animation={props.animation ? props.animation : "border"}
        variant={props.variant ? props.variant : "success"}
        size={props.size ? props.size : "lg"}
      />
    </div>
  );
}

export default Loader;
