import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner, Form, Alert } from "react-bootstrap";
import { UserController } from "../../../../../Redux/controllers/UserController";
import Utility from "../../../../../utils/Utility";
import { useSelector } from "react-redux";

export default function MigrationModal({ show, onHide, user, onMigrate }) {
  const [societies, setSocieties] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [isFamilyHead, setIsFamilyHead] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [loadingFamilyMembers, setLoadingFamilyMembers] = useState(false);
  const [loadingSocieties, setLoadingSocieties] = useState(false);
  const [migrateLoading, setMigrateLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const { token } = useSelector((state) => state.user);

  console.log("userrrr", user);

  useEffect(() => {
    if (show && user) {
      resetForm();
      setIsFamilyHead(user.isHeadOfFamily);
      if (user.isHeadOfFamily) {
        fetchFamilyMembers();
      }
      fetchSocietiesList();
    }
  }, [user, show]);

  const resetForm = () => {
    setSelectedSociety("");
    setApartmentNumber("");
    setFamilyMembers([]);
    setValidationError("");
  };

  const fetchSocietiesList = async () => {
    setLoadingSocieties(true);
    try {
      const { data } = await UserController.getSociety(token);
      // Filter out the current society
      const filteredSocieties = (data?.data || []).filter(
        (society) => society._id !== user?.societyDetails?.society
      );
      setSocieties(filteredSocieties);
    } catch (error) {
      console.error("Error fetching societies:", error);
      Utility.eToast("Failed to fetch societies");
    } finally {
      setLoadingSocieties(false);
    }
  };

  const fetchFamilyMembers = async () => {
    if (!user?._id) return;

    setLoadingFamilyMembers(true);
    try {
      const { data } = await UserController.getFamilyMembersByHeadId(
        token,
        user._id
      );
      setFamilyMembers(data?.data?.familyMembers || []);
    } catch (error) {
      console.error("Error fetching family members:", error);
      Utility.eToast("Failed to fetch family members");
    } finally {
      setLoadingFamilyMembers(false);
    }
  };

  const validateForm = () => {
    if (!selectedSociety) {
      setValidationError("Please select a society");
      return false;
    }
    if (!apartmentNumber.trim()) {
      setValidationError("Please enter an apartment number");
      return false;
    }
    // Add additional apartment number validation if needed
    // For example, checking format, length, etc.
    if (!/^[A-Za-z0-9-]+$/.test(apartmentNumber.trim())) {
      setValidationError(
        "Apartment number can only contain letters, numbers, and hyphens"
      );
      return false;
    }
    setValidationError("");
    return true;
  };

  const handleMigrate = async () => {
    if (!validateForm()) return;

    setMigrateLoading(true);
    try {
      if (isFamilyHead) {
        await UserController.migrateFamily(token, {
          familyHeadId: user._id,
          newSocietyId: selectedSociety,
          newApartmentNumber: apartmentNumber.trim().toUpperCase(),
        });
        Utility.sToast("Family migration successful!");
      } else {
        await UserController.migrateResident(token, {
          residentId: user._id,
          newSocietyId: selectedSociety,
          newApartmentNumber: apartmentNumber.trim().toUpperCase(),
        });
        Utility.sToast("Resident migration successful!");
      }
      onMigrate();
      onHide();
    } catch (error) {
      console.error("Migration error:", error);
      Utility.eToast(error?.response?.data?.message || "Migration failed");
    } finally {
      setMigrateLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      >
        <Modal.Title>
          {isFamilyHead ? "Migrate Family" : "Migrate Resident"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {validationError && (
          <Alert variant="danger" className="mb-3">
            {validationError}
          </Alert>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Current User</Form.Label>
          <Form.Control
            type="text"
            value={`${user?.firstName || ""} ${user?.lastName || ""}`}
            readOnly
            className="bg-light"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Current Society</Form.Label>
          <Form.Control
            type="text"
            value={user?.societyDetails?.society?.societyName || "N/A"}
            readOnly
            className="bg-light"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Current Apartment</Form.Label>
          <Form.Control
            type="text"
            value={user?.societyDetails?.apartmentNumber || "N/A"}
            readOnly
            className="bg-light"
          />
        </Form.Group>

        <hr />

        <Form.Group className="mb-3">
          <Form.Label>
            Select New Society <span className="text-danger">*</span>
          </Form.Label>
          {loadingSocieties ? (
            <div className="text-center">
              <Spinner animation="border" size="sm" />
            </div>
          ) : (
            <Form.Select
              value={selectedSociety}
              onChange={(e) => {
                setSelectedSociety(e.target.value);
                setValidationError("");
              }}
              isInvalid={!!validationError && !selectedSociety}
            >
              <option value="">Choose Society</option>
              {societies.map((society) => (
                <option key={society._id} value={society._id}>
                  {society.societyName}
                </option>
              ))}
            </Form.Select>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            New Apartment Number <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={apartmentNumber}
            onChange={(e) => {
              setApartmentNumber(e.target.value);
              setValidationError("");
            }}
            placeholder="Enter new apartment number"
            isInvalid={!!validationError && !apartmentNumber}
          />
          <Form.Text className="text-muted">
            Enter apartment number in the format specified by the society
          </Form.Text>
        </Form.Group>

        {isFamilyHead && (
          <div className="mt-4">
            <h6>Family Members to be Migrated:</h6>
            {loadingFamilyMembers ? (
              <div className="text-center">
                <Spinner animation="border" size="sm" />
              </div>
            ) : familyMembers.length > 0 ? (
              <div className="family-members-list">
                {familyMembers.map((member, index) => (
                  <div
                    key={member._id}
                    className="d-flex align-items-center mb-2"
                  >
                    <span className="me-2">{index + 1}.</span>
                    <div>
                      <div>{`${member.firstName} ${member.lastName}`}</div>
                      <small className="text-muted">{member.email}</small>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-muted">No family members found</p>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={migrateLoading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleMigrate}
          disabled={!selectedSociety || !apartmentNumber || migrateLoading}
        >
          {migrateLoading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Migrating...
            </>
          ) : (
            `Migrate ${isFamilyHead ? "Family" : "Resident"}`
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
