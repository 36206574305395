import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Nav, Alert } from "react-bootstrap";
import CardComp from "../../../Globalcomponent/Card/CardComp";
import { AiOutlinePlusCircle } from "react-icons/ai";
import NoticeFormModal from "./Components/NoticeFormModal/NoticeFormModal";
import "./NoticeManagement.css";
import { UserController } from "../../../Redux/controllers/UserController";
import { HttpClient } from "../../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Loader from "./../../../components/Loader/Loader";
import PaginationComp from "../../../Globalcomponent/Pagination/Pagination";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Utility from "../../../utils/Utility";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function NoticeManagement() {
  let DOCS_PER_PAGE = 10;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("received");
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNotices, setTotalNotices] = useState(1);
  const [allNotices, setAllNotices] = useState([]);
  const [myCreatedNotices, setMyCreatedNotices] = useState([]);
  const { token, user } = useSelector((st) => st?.user);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(null);

  const getAllNotices = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const { data } = await UserController.getSocietyAdminNotices(
        token,
        activePage,
        DOCS_PER_PAGE
      );

      // Robust data handling
      const notices = Array.isArray(data?.data?.notices)
        ? data.data.notices
        : Array.isArray(data?.notices)
        ? data.notices
        : [];

      setAllNotices(notices);
      setTotalPages(data?.data?.totalPages || data?.totalPages || 1);
      setTotalNotices(
        data?.data?.totalNotices || data?.totalNotices || notices.length
      );
    } catch (error) {
      setError(error.message || "Unable to fetch notices");
      if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast("Unable to get notices! Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNoticesCreatedByMe = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const { data } = await HttpClient.apiCallerUsingV1(
        `notice/by-sender/${user._id}`,
        "GET",
        {},
        token
      );

      // Robust data handling
      // const createdNotices = Array.isArray(data?.data)
      //   ? data.data
      //   : Array.isArray(data)
      //   ? data
      //   : [];

      console.log("first", typeof data.data.notices);
      console.log("first", data.data.notices);

      setMyCreatedNotices(data.data.notices);
    } catch (error) {
      setError(error.message || "Unable to fetch created notices");
      Utility.eToast("Unable to fetch created notices!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      if (activeTab === "received") {
        await getAllNotices();
      } else {
        await getNoticesCreatedByMe();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "received") {
      getAllNotices();
    } else {
      getNoticesCreatedByMe();
    }
  }, [activeTab, activePage, isDeleted]);

  // Determine which notices to display
  const displayNotices =
    activeTab === "received" ? allNotices : myCreatedNotices;

  return (
    <>
      <Container className="mt-4" id="main-container">
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Notice Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        <Row className="mb-3">
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <Nav.Item>
              <Nav.Link eventKey="received">Notices Received</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="created">My Created Notices</Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>

        <Row className="search-and-filter">
          <Col
            md={12}
            xs={12}
            className="d-flex align-items-center justify-content-end"
          >
            {activeTab === "created" && (
              <Button id="notice-button" onClick={() => setModal(true)}>
                <AiOutlinePlusCircle id="notice-icon" /> Add Notice
              </Button>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: "1rem" }}>
          <Col style={{ fontSize: "1.2rem", color: "gray" }}>
            {activeTab === "received"
              ? "Received Notices"
              : "Notices Created by Me"}
          </Col>
          <hr />
        </Row>

        {error && (
          <Row>
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}

        <Row>
          {isLoading ? (
            <Col xs={12}>
              <Loader />
            </Col>
          ) : displayNotices && displayNotices.length > 0 ? (
            displayNotices.map((item) => (
              <Col xs={12} md={6} key={item._id}>
                <CardComp
                  content={item.content}
                  date={item.createdAt}
                  title={item.title}
                  noticeId={item?._id}
                  deleted={() => setIsDeleted((prev) => !prev)}
                />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <Alert variant="info">No notices found.</Alert>
            </Col>
          )}
        </Row>

        {totalNotices > DOCS_PER_PAGE && (
          <PaginationComp
            totalPages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}

        <NoticeFormModal
          show={modal}
          onHide={() => setModal(false)}
          setRefresh={setRefresh}
        />
      </Container>
    </>
  );
}
