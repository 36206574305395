import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import "./Complaints.css";
import CardComp from "../../../Globalcomponent/Card/CardComp";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Loader from "./../../../components/Loader/Loader";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "../../../utils/Excel";
import Utility from "../../../utils/Utility";
import BtnLoader from "../../../components/Loader/BtnLoader";
export default function Complaints() {
  const [complaintData, setComplaintData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const getAllComplaints = async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getComplaints(token);
      setComplaintData(data?.data || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllComplaints();
  }, []);

  const handleExport = () => {
    let excelData = complaintData.map((item) => ({
      Date: item?.createdAt ? Utility.formatDate(item?.createdAt) : "N/A",
      Name: item?.sender?.firstName
        ? item?.sender?.firstName + " " + item?.sender?.lastName
        : "N/A",
      "Apartment Name": item?.society?.societyName || "N/A",
      "Flat No": item?.sender?.societyDetails?.apartmentNumber || "N/A",
      Subject: item?.title || "N/A",
      Details: item?.content || "N/A",
      Status: item?.status,
    }));
    exportToExcel(excelData, "complaint_list");
  };

  const handleRefresh = async () => {
    setRefreshLoading(true); // Enable refresh loader
    try {
      await getAllComplaints();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false); // Disable refresh loader
    }
  };
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem", paddingBottom: "2rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
        <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Complaints
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        <ExportButton onClick={handleExport} />
        <Row className="recent-row">
          <Col className="recent-complaints">Recent Complaints</Col>
          <hr />
        </Row>
        {loading ? (
          <Loader />
        ) : (
          <>
            {complaintData?.length > 0 ? (
              <>
                {complaintData.map((item) => (
                  <CardComp
                    content={item?.content}
                    date={item?.createdAt}
                    key={item?._id}
                    title={item?.title}
                    isComplaint={true}
                    apartmentName={item?.society?.societyName || "N/A"}
                    apartmentNo={
                      item?.sender?.societyDetails?.apartmentNumber || "N/A"
                    }
                    sender={
                      item?.sender?.firstName
                        ? item?.sender?.firstName + " " + item?.sender?.lastName
                        : "N/A"
                    }
                    status={item?.status}
                  />
                ))}
              </>
            ) : (
              <Row className="mt-5">
                <Col>No Complaint Found</Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
}
