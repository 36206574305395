import React from 'react'
import { Container } from 'react-bootstrap'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
    { name: 'Mon', uv: 300, pv: 470, amt: 2400 },
    { name: 'Tue', uv: 400, pv: 520, amt: 2210 },
    { name: 'Wed', uv: 500, pv: 480, amt: 2290 },
    { name: 'Thus', uv: 600, pv: 460, amt: 2000 },
    { name: 'Fri', uv: 700, pv: 580, amt: 2181 },
    { name: 'Sat', uv: 800, pv: 720, amt: 2500 },
    { name: 'Sun', uv: 900, pv: 590, amt: 2100 },
];

export default function BarChat() {
  return (
    <>
         <Container style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap={25}
                    barGap={50}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#1A7857" radius={[4, 4, 0, 0]} activeBar={<Rectangle fill="#1A7857" stroke="#1A7857" />} />
                </BarChart>
            </ResponsiveContainer>
        </Container>
    </>
  )
}
