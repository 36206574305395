import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import "./NoticeFormModal.css";
import { getSocket } from "../../../../../services/socketService";
import Multiselect from "multiselect-react-dropdown";
import { UserController } from "./../../../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "./../../../../../utils/Utility";
import BtnLoader from "../../../../../components/Loader/BtnLoader";

export default function NoticeFormModal(props) {
  const { token } = useSelector((st) => st?.user);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [societyIds, setSocietyIds] = useState([]);
  const [subAdminSubRole, setSubAdminSubRoles] = useState({
    subCorporate: false,
    subSociety: false,
  });
  const [employeeSubRole, setEmployeeSubRoles] = useState({
    subGateman: false,
  });
  const [roles, setRoles] = useState({
    all: true,
    resident: true,
    society: true,
    subAdmin: true,
    employee: true,
  });
  const [roleData, setRoleData] = useState({
    residentData: [],
    societyData: [],
    subAdminData: [],
    employeeData: [],
  });

  const handleClose = () => {
    setContent("");
    setTitle("");
    props.onHide();
  };

  const getResidentData = async () => {
    try {
      const { data } = await UserController.getResidents(token);
      let list = data?.data?.users?.map((item) => ({
        name: item?.firstName + " " + item?.lastName,
        _id: item?._id,
      }));
      setRoleData((prev) => ({
        ...prev,
        residentData: list,
      }));
    } catch (error) {}
  };
  const getSocietyData = async () => {
    try {
      const { data } = await UserController.getSociety(token);
      let list = data?.data?.map((item) => ({
        name: item?.societyName,
        _id: item?._id,
      }));
      setRoleData((prev) => ({
        ...prev,
        societyData: list,
      }));
    } catch (error) {}
  };
  const getSubAdminData = async () => {
    try {
      const { data } = await UserController.getSubAdmins(token);
      let list = data?.data?.users?.map((item) => ({
        name: item?.firstName + " " + item?.lastName,
        _id: item?._id,
      }));
      setRoleData((prev) => ({
        ...prev,
        subAdminData: list,
      }));
    } catch (error) {}
  };
  const getEmployeeData = async () => {
    try {
      const { data } = await UserController.getEmployees(token);
      let list = data?.data?.users?.map((item) => ({
        name: item?.firstName + " " + item?.lastName,
        _id: item?._id,
      }));
      setRoleData((prev) => ({
        ...prev,
        employeeData: list,
      }));
    } catch (error) {}
  };

  const toggleAll = (e) => {
    const { checked } = e.target;
    setRoles((prev) => ({
      ...prev,
      all: checked,
      resident: checked,
      society: checked,
      employee: checked,
      subAdmin: checked,
    }));
    if (checked === false) {
      getResidentData();
      getSocietyData();
      getSubAdminData();
      getEmployeeData();
    }
  };

  useEffect(() => {
    if (props.show) {
      setRoles({
        all: true,
        resident: true,
        society: true,
        subAdmin: true,
        employee: true,
      });
      setSubAdminSubRoles({
        subCorporate: false,
        subSociety: false,
      });
      setEmployeeSubRoles({
        subGateman: false,
      });
    }
  }, [props.show]);

  useEffect(() => {
    const allTrue = Object.values(roles)
      .slice(1)
      .every((item) => item === true);
    if (roles.all !== allTrue) {
      setRoles((prev) => ({ ...prev, all: allTrue }));
    }
  }, [roles]);

  const handlePublish = async () => {
    try {
      if (!title) {
        return Utility.eToast("Title is Required");
      }
      if (!content) {
        return Utility.eToast("Content is Required");
      }
      setLoading(true);

      let finalData = {
        title,
        content,
        targetAudience: {},
      };

      let flag = true;

      if (roles.all) {
        finalData.targetAudience.allUsers = true;
        flag = false;
      } else {
        let rolesArr = [];

        if (recipients.length > 0) {
          finalData.recipients = recipients;
          flag = false;
        }

        if (roles.resident) {
          rolesArr.push("RESIDENT");
          flag = false;
        }

        if (roles.society) {
          finalData.targetAudience.societies =
            roleData.societyData.map((item) => item._id) || [];
          flag = false;
        } else {
          if (societyIds.length > 0) {
            finalData.targetAudience.societyId = societyIds;
            flag = false;
          }
        }

        if (roles.employee) {
          rolesArr.push("EMPLOYEE");
          flag = false;
        } else {
          let empSubRoles = [];
          if (employeeSubRole.subGateman) {
            empSubRoles.push("GATEMAN");
            flag = false;
          }
          finalData.targetAudience.employeeSubRoles = empSubRoles;
        }

        if (roles.subAdmin) {
          rolesArr.push("SUBADMIN");
          flag = false;
        } else {
          let subAdminSubRoles = [];
          if (subAdminSubRole.subCorporate) {
            subAdminSubRoles.push("CORPORATE");
            flag = false;
          }
          if (subAdminSubRole.subSociety) {
            subAdminSubRoles.push("SOCIETY");
            flag = false;
          }
          finalData.targetAudience.subAdminSubRoles = subAdminSubRoles;
        }

        finalData.targetAudience.roles = rolesArr;
      }

      if (flag) {
        return Utility.eToast("Select Recipient");
      }

      const { data } = await UserController.addNotice(finalData, token);
      Utility.sToast(data.message);
      props.setRefresh((prev) => !prev);
      handleClose();
    } catch (error) {
      Utility.eToast(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setRoles((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const handleSubAdminChange = (e) => {
    let { checked, name } = e.target;
    setSubAdminSubRoles((prev) => {
      const updatedRoles = { ...prev, [name]: checked };
      const bothSelected = updatedRoles.subCorporate && updatedRoles.subSociety;

      if (bothSelected) {
        setRoles((prev) => ({ ...prev, subAdmin: true }));
      } else if (!updatedRoles.subCorporate && !updatedRoles.subSociety) {
        setRoles((prev) => ({ ...prev, subAdmin: false }));
      }

      return updatedRoles;
    });
  };
  const handleEmployeeChange = (e) => {
    let { checked, name } = e.target;
    setEmployeeSubRoles((prev) => ({ ...prev, [name]: checked }));
  };
  // const sendUpdate = () => {
  //   const socket = getSocket();
  //   socket.emit("data", {
  //     to: "669a00b12a83ebacdd1548da",
  //     someData: "data from Abhisek",
  //   });
  // };
  // const sendNotice = () => {
  //   const socket = getSocket();
  //   socket.emit("notice", {
  //     to: "669a00b12a83ebacdd1548da",
  //     someData: "notice from Abhisek",
  //   });
  // };

  return (
    <>
      <Container id="add-notice-form-container">
        <Modal size="xl" {...props} onHide={handleClose}>
          <Container style={{ minHeight: "90vh", padding: "1rem" }}>
            {/* <div className="d-flex align-items-center justify-content-end w-100 my-1">
              <RxCross2 id="crossss" onClick={props.onHide} />
            </div> */}
            <Row>
              <Col xs={12} className="write-notice">
                Write a Notice
                <hr className="hr-line" />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={6}
                // className="overflow-y-auto "
                style={{ padding: "0rem 2rem" }}
              >
                <Form.Check
                  className="custom-checkbox check-box "
                  id="all"
                  name="all"
                  label="All"
                  checked={roles?.all}
                  onChange={toggleAll}
                />

                <Form.Check
                  className=" check-box custom-checkbox"
                  id="resident"
                  name="resident"
                  label="All Residents"
                  checked={roles?.resident}
                  onChange={handleChange}
                  disabled={roles?.all}
                />

                {!roles?.resident && (
                  <div className="my-1">
                    <Form.Label id="choose-specific">
                      Choose Specific Resident
                    </Form.Label>
                    <Multiselect
                      className="custom-multiselect"
                      options={roleData.residentData}
                      onSelect={(_, selectedItem) => {
                        let val = selectedItem?._id;
                        setRecipients((prev) => {
                          if (!prev.includes(val)) {
                            return [...prev, val];
                          }
                          return prev;
                        });
                      }}
                      onRemove={(_, removedItem) => {
                        let val = removedItem?._id;
                        setRecipients((prev) =>
                          prev.filter((item) => item !== val)
                        );
                      }}
                      displayValue="name"
                    />
                  </div>
                )}

                <Form.Check
                  className=" check-box custom-checkbox"
                  id="society"
                  name="society"
                  label="All Societies"
                  checked={roles?.society}
                  onChange={handleChange}
                  disabled={roles?.all}
                />

                {!roles?.society && (
                  <div className="my-1">
                    <Form.Label id="choose-specific">Choose Society</Form.Label>
                    <Multiselect
                      options={roleData?.societyData} // Options to display in the dropdown
                      onSelect={(_, selectedItem) => {
                        let val = selectedItem?._id;
                        setSocietyIds((prev) => {
                          if (!prev.includes(val)) {
                            return [...prev, val];
                          }
                          return prev;
                        });
                      }}
                      onRemove={(_, removedItem) => {
                        let val = removedItem?._id;
                        setSocietyIds((prev) =>
                          prev.filter((item) => item !== val)
                        );
                      }}
                      displayValue="name"
                    />
                  </div>
                )}

                <Form.Check
                  className=" check-box custom-checkbox"
                  id="subAdmin"
                  name="subAdmin"
                  label="All Sub Admins"
                  checked={roles?.subAdmin}
                  onChange={handleChange}
                  disabled={roles?.all}
                />
                {!roles?.subAdmin && (
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Form.Check
                      className=" check-box-small custom-checkbox"
                      id="subAdmin"
                      name="subCorporate"
                      label="All Corporates"
                      checked={subAdminSubRole?.subCorporate}
                      onChange={handleSubAdminChange}
                      disabled={roles?.all}
                    />
                    <Form.Check
                      className=" check-box-small custom-checkbox"
                      id="subAdmin"
                      name="subSociety"
                      label="All Societies"
                      checked={subAdminSubRole?.subSociety}
                      onChange={handleSubAdminChange}
                      disabled={roles?.all}
                    />
                  </div>
                )}

                {!roles?.subAdmin &&
                  (!subAdminSubRole?.subCorporate ||
                    !subAdminSubRole?.subSociety) && (
                    <div className="my-1">
                      <Form.Label id="choose-specific">
                        Choose Sub Admin
                      </Form.Label>
                      <Multiselect
                        options={roleData?.subAdminData} // Options to display in the dropdown
                        onSelect={(_, selectedItem) => {
                          let val = selectedItem?._id;
                          setRecipients((prev) => {
                            if (!prev.includes(val)) {
                              return [...prev, val];
                            }
                            return prev;
                          });
                        }}
                        onRemove={(_, removedItem) => {
                          let val = removedItem?._id;
                          setRecipients((prev) =>
                            prev.filter((item) => item !== val)
                          );
                        }}
                        displayValue="name"
                      />
                    </div>
                  )}

                <Form.Check
                  className=" check-box custom-checkbox"
                  id="employee"
                  name="employee"
                  label="All Employees"
                  checked={roles?.employee}
                  onChange={handleChange}
                  disabled={roles?.all}
                />
                {!roles?.employee && (
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Form.Check
                      className=" check-box-small custom-checkbox"
                      id="subGateman"
                      name="subGateman"
                      label="All Gatemen"
                      checked={employeeSubRole?.subGateman}
                      onChange={handleEmployeeChange}
                      disabled={roles?.all}
                    />
                  </div>
                )}
                {!roles?.employee && (
                  <div className="my-1">
                    <Form.Label id="choose-specific">
                      Choose Employee
                    </Form.Label>
                    <Multiselect
                      options={roleData?.employeeData} // Options to display in the dropdown
                      onSelect={(_, selectedItem) => {
                        let val = selectedItem?._id;
                        setRecipients((prev) => {
                          if (!prev.includes(val)) {
                            return [...prev, val];
                          }
                          return prev;
                        });
                      }}
                      onRemove={(_, removedItem) => {
                        let val = removedItem?._id;
                        setRecipients((prev) =>
                          prev.filter((item) => item !== val)
                        );
                      }}
                      displayValue="name"
                    />
                  </div>
                )}
              </Col>

              {/* 2nd */}
              <Col xs={12} sm={6} style={{ padding: "0rem 2rem" }} className="">
                <Form>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label id="title-label">Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      id="null-placeholder"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label id="title-label">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder=""
                      id="null-placeholder1"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group>
                </Form>

                <div id="button-container">
                  <div id="button-wrapper">
                    <Button
                      id="action-button close-btn"
                      onClick={handleClose}
                      variant="secondary"
                    >
                      Close
                    </Button>
                  </div>
                  <div id="button-wrapper">
                    {loading ? (
                      <Button
                        id="action-button publish-btn"
                        onClick={handlePublish}
                        variant="success"
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "24px",
                            padding: "0px",
                            borderRadius: "15px",
                          }}
                        >
                          <h6
                            style={{
                              fontSize: "1rem",
                              marginBottom: "0px",
                              marginRight: "5px",
                            }}
                          >
                            Publishing{" "}
                          </h6>
                          <BtnLoader />
                        </div>
                      </Button>
                    ) : (
                      <Button
                        id="action-button publish-btn"
                        onClick={handlePublish}
                        variant="success"
                        disabled={title === "" || content === ""}
                      >
                        Publish
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
    </>
  );
}
