import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import SubAdminModal from "./SubAdminModal/SubAdminModal";
import { useDispatch, useSelector } from "react-redux";
import {
  approveSubAdmin,
  getUserListAccordingToRole,
} from "../../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../utils/Utility";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Loader from "../../../components/Loader/Loader";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "../../../utils/Excel";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import ActionButton from "../../../Globalcomponent/ActionButtons/ActionButton";
import { UserController } from "../../../Redux/controllers/UserController";
import "./SubAdmin.css";
import ConfirmModal from "../../../Globalcomponent/ConfirmModal/ConfirmModal";
import { BiTrash } from "react-icons/bi";

export default function SubAdmin() {
  const dispatch = useDispatch();
  const { token, isUserListLoading } = useSelector((state) => state.user);

  const [selectedSubrole, setSelectedSubrole] = useState("SOCIETY");
  const [userListAccordingToRole, setUserListAccordingToRole] = useState([]);
  const [showVerifySubadminModal, setShowVerifySubadminModal] = useState(false);
  const [selectedSubadminToVerify, setSelectedSubadminToVerify] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  // Confirmation modal state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getUserListAccordingToRoleFun();
  }, [selectedSubrole, refresh]);
  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getUserListAccordingToRoleFun();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };
  const getUserListAccordingToRoleFun = () => {
    dispatch(
      getUserListAccordingToRole({
        role: "SUBADMIN",
        subrole: selectedSubrole,
        token: token,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const allusers = res?.data?.users;
        setUserListAccordingToRole({
          totalUser: res?.data?.totalUsers,
          users: allusers,
        });
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Get User List ! Please try again later.");
        }
      });
  };

  const handleDelete = (item) => {
    setConfirmTitle("Are you sure you want to delete?");
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        // Replace this with your actual delete API call
        const response = await UserController.deleteSubAdmin(token, item?._id);
        Utility.sToast(response?.data?.message || "deleted successfully");
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || "Failed to delete");
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const approveSubadminRequestFun = (item) => {
    setConfirmTitle(`Are you sure you want to approve sub admin?`);
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      setSelectedSubadminToVerify(item?._id);

      const data = {
        id: item?._id,
        isApproved: true,
      };
      try {
        await dispatch(approveSubAdmin({ data: data, token: token })).then(
          unwrapResult
        );
        getUserListAccordingToRoleFun();
      } catch (err) {
        Utility.eToast(err?.response?.data?.message || err?.message);
      } finally {
        setConfirmLoading(false);
        setSelectedSubadminToVerify("");
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const handleToggleStatus = (item) => {
    setConfirmTitle(
      `Are you sure you want to ${
        item?.isActive ? "deactivate" : "activate"
      } sub admin?`
    );
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const toggleData = {
          userId: item?._id,
          isActive: !item?.isActive,
        };
        const response = await UserController.userToggleStatus(
          token,
          toggleData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const column = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sr No.</span>,
        size: 50,
      },
      {
        accessorKey: "Name",
        header: "Name",
        enableResizing: true,
        size: 300,
      },
      {
        accessorKey: "Mobile No",
        header: "Phone Number",
        size: 50,
      },
      {
        accessorKey: "Email",
        header: "Email Id",
        size: 50,
      },
      {
        accessorKey: "Society",
        header: "Society",
        size: 50,
      },
      // {
      //   accessorKey: "Sub Role",
      //   header: "Sub Role",
      //   size: 50,
      // },
      {
        accessorKey: "status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "view",
        header: "Actions",
        enableResizing: true,
        size: 300,
      },
    ],
    []
  );

  const data =
    userListAccordingToRole?.users?.length > 0 &&
    userListAccordingToRole?.users.map((item, i) => ({
      srno: i + 1,
      Name:
        item?.firstName && item?.lastName
          ? item?.firstName + " " + item?.lastName
          : (item?.firstName && item?.firstName) ||
            (item?.lastName && item?.lastName) ||
            "N/A",
      "Mobile No": item?.phoneNumber || "N/A",
      Email: item?.email || "N/A",
      Society: item?.societyDetails?.society?.societyName || "N/A",
      // "Sub Role": item?.subRole || "N/A",
      "Verification Status": item?.isApproved ? "Verified" : "Not Verified",
      "Active Status": item?.isActive ? "Active" : "Inactive",
      status: (
        <div
          style={{
            color: item?.isActive ? "#1a7857" : "red",
            fontWeight: 500,
          }}
        >
          {item?.isActive ? "ACTIVE" : "INACTIVE"}
        </div>
      ),
      view: (
        <div className="d-flex align-items-center gap-3">
          <ActionButton
            title={item?.isApproved ? "VERIFIED" : "VERIFY"}
            color={"#1a7857"}
            width={"5rem"}
            onClick={() => approveSubadminRequestFun(item)}
            disabled={
              selectedSubadminToVerify === item?._id || item?.isApproved
            }
          />

          {item?.isActive ? (
            <ActionButton
              title={"De-Activate"}
              icon={<FiUserX />}
              color={"rgb(109, 117, 124)"}
              width={"8rem"}
              onClick={() => handleToggleStatus(item)}
            />
          ) : (
            <ActionButton
              title={"Activate"}
              icon={<FiUserCheck />}
              color={"#1a7857"}
              width={"8rem"}
              onClick={() => handleToggleStatus(item)}
            />
          )}

          <ActionButton
            title={"Delete"}
            icon={<BiTrash />}
            color={"#dc3545"}
            width={"6rem"}
            onClick={() => handleDelete(item)}
          />
        </div>
      ),
    }));

  const handleExport = () => {
    let excelData = data.map(({ srno, view, status, ...rest }) => rest);
    exportToExcel(
      excelData,
      `subadmin_${selectedSubrole.toLocaleLowerCase()}-list`
    );
  };

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Sub-admin Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <Spinner animation="border" size="sm" />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        <div id="subadminBtns">
          <Button
            variant="light"
            id={
              selectedSubrole === "SOCIETY"
                ? "selectedSubroleBtn"
                : "subroleBtn"
            }
            onClick={() => {
              setSelectedSubrole("SOCIETY");
            }}
          >
            Society Admin
          </Button>
          <Button
            variant="light"
            id={
              selectedSubrole === "CORPORATE"
                ? "selectedSubroleBtn"
                : "subroleBtn"
            }
            onClick={() => {
              setSelectedSubrole("CORPORATE");
            }}
          >
            Corporate Admin
          </Button>
        </div>

        {isUserListLoading ? (
          <Loader />
        ) : (
          <>
            {userListAccordingToRole?.users?.length > 0 ? (
              <>
                <ExportButton onClick={handleExport} />
                <Row>
                  <DataTable columns={column} data={data} />
                </Row>
              </>
            ) : (
              <Row>
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-center mt-5 fw-semibold"
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
        loading={confirmLoading}
      />
    </>
  );
}
