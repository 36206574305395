import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

export default function VerifyFamilyModal({ show, onHide, family, onApprove }) {
  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    setLoading(true);
    try {
      await onApprove();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify Family</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to verify the family member{" "}
          <strong>{family?.firstName} {family?.lastName}</strong>?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleApprove} disabled={loading}>
          {loading ? (
            <>
              Verifying <Spinner animation="border" size="sm" />
            </>
          ) : (
            "Verify"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
