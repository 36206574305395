import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./EmergencySystemCard.css";
export default function EmergencySystemCard() {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card id="card-row">
              <Row>
                <Col xs={12} md={3}>
                  {/* <Col xs={12} md={3} id="card-col"> */}
                  <Row>
                    <Col
                      md={7}
                      xs={7}
                      sm={6}
                      lg={7}
                      className="appartment-name"
                    >
                      Apartment Name
                    </Col>
                    <Col md={5} xs={5} sm={6} lg={5}>
                      <Button className="z1-button">Z1 Apartment</Button>
                    </Col>
                  </Row>

                </Col>
                <Col xs={12} md={3}>
                  <Row>
                    <Col
                      md={4}
                      xs={7}
                      sm={6}
                      lg={4}
                      className="appartment-name1"
                    >
                      Flat No.
                    </Col>
                    <Col md={8} xs={5} sm={6} lg={8}>
                      <Button className="all-button">All</Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={3}>
                  <Row>
                    <Col
                      md={4}
                      xs={7}
                      sm={6}
                      lg={4}
                      className="appartment-name1"
                    >
                      Name
                    </Col>

                    <Col md={8} xs={5} sm={6} lg={8}>
                      <Button className="all-button">John D </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={3} className="date-col">
                  <span className="date">Date:&nbsp;&nbsp; </span> Dec 5 2024
                </Col>
              </Row>
              <Row style={{ paddingLeft: "0.3rem", marginTop: "0.5rem" }}>
                <Col id="dear">Attention Residents:</Col>
              </Row>
              <Row style={{ paddingLeft: "0.3rem", marginTop: "1rem" }}>
                <Col id="dear">
                  A wild dog has been spotted on floor 40. Please avoid the area
                  and ensure doors are securely closed. Immediate action is
                  being taken.
                </Col>
              </Row>
              <Row style={{ paddingLeft: "0.3rem", marginTop: "1rem" }}>
                <Col id="dear">
                  Stay safe,
                  <br /> John D , Flat 40
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
