import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { UserController } from "../../../../Redux/controllers/UserController";
import Utility from "../../../../utils/Utility";
import DataTable from "../../../../Globalcomponent/DataTable/DataTable";
import Loader from "../../../../components/Loader/Loader";

export default function LogModal(props) {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);

  const getLogDetails = async () => {
    try {
      setLoading(true);

      const { data } = await UserController.getVisitorLogDetails(
        token,
        props?.selectedVisitor?._id
      );
      setLogData(data?.data || []);
    } catch (err) {
      Utility.eToast(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.show && props?.selectedVisitor?._id) {
      getLogDetails();
    }
  }, [props.show, props?.selectedVisitor?._id]);

  const column = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sl No</span>,
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 50,
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        size: 50,
      },
      {
        accessorKey: "apartmentName",
        header: "Apartment",
        size: 150,
      },
      {
        accessorKey: "flatNo",
        header: "Flat No",
        size: 50,
      },
      {
        accessorKey: "checkIn",
        header: "Check-In",
        size: 50,
      },
      {
        accessorKey: "checkOut",
        header: "Check-Out",
        size: 50,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 50,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      logData?.map((item, i) => ({
        srno: <span className="srnooo">{i + 1}</span>,
        name: item?.visitorId?.name || "N/A",
        phoneNumber: item?.visitorId?.phoneNumber || "N/A",
        apartmentName: item?.society?.societyName || "N/A",
        flatNo: item?.hostResident?.societyDetails?.apartmentNumber || "N/A",
        checkIn:
          (item?.checkInTime && Utility.formatDate(item?.checkInTime)) || "N/A",
        checkOut:
          (item?.checkOutTime && Utility.formatDate(item?.checkOutTime)) ||
          "N/A",
        status: item?.status || "N/A",
      })),
    [logData]
  );

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#1A7857" }}
        >
          Log Details{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: "50vh",
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <div style={{ marginTop: "-8rem" }}>
            <Loader />
          </div>
        ) : (
          <>
            {logData?.length > 0 ? (
              <DataTable
                columns={column}
                data={data}
                enablePagination={false}
                enableGlobalFilter={false}
              />
            ) : (
              <h5
                style={{
                  minHeight: "40vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Log found
              </h5>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
