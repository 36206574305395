import React from "react";
import { Route, Routes } from "react-router-dom";
import Maintenance from "../AdminWeb/SocietyAdminPages/Maintenance/Maintenance";
import NoticeManagement from "../AdminWeb/SocietyAdminPages/NoticeManagement/NoticeManagement";
import Complaints from "../AdminWeb/SocietyAdminPages/Complaints/Complaints";
import EmergencySystem from "../AdminWeb/SocietyAdminPages/EmergencySystem/EmergencySystem";
import DashboardPage from "../AdminWeb/SocietyAdminPages/Dashboard/DashboardPage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Profile from "../AdminWeb/Pages/Profile/Profile";
import Families from "../AdminWeb/SocietyAdminPages/Families/Families";
import Archives from "../AdminWeb/SocietyAdminPages/Archives/Archives";

export default function SocietyAdminRootRouter() {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route path="/notice" element={<NoticeManagement />} />
      <Route path="/complaints" element={<Complaints />} />
      <Route path="/emergency" element={<EmergencySystem />} />
      <Route path="/families" element={<Families />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/archives" element={<Archives />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
