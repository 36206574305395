import React, { useState, useCallback } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { UserController } from "../../../Redux/controllers/UserController";

const INITIAL_FORM_STATE = {
  email: "",
  otp: "",
  newPassword: "",
  confirmPassword: "",
};

const ERROR_MESSAGES = {
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Please enter a valid email address",
  OTP_REQUIRED: "OTP is required",
  OTP_LENGTH: "OTP must be 6 digits",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_LENGTH: "Password must be at least 8 characters",
  PASSWORD_MISMATCH: "Passwords do not match",
};

export const ResetPasswordModal = ({ show, onHide }) => {
  // Form state
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [step, setStep] = useState(1);

  // Status states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Validation state
  const [errors, setErrors] = useState({});

  // Email validation
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email?.toLowerCase());
  };

  // Form validation
  const validateForm = useCallback(() => {
    const newErrors = {};

    if (step === 1) {
      if (!formData.email) {
        newErrors.email = ERROR_MESSAGES.EMAIL_REQUIRED;
      } else if (!validateEmail(formData.email)) {
        newErrors.email = ERROR_MESSAGES.EMAIL_INVALID;
      }
    } else if (step === 2) {
      if (!formData.otp) {
        newErrors.otp = ERROR_MESSAGES.OTP_REQUIRED;
      } else if (formData.otp.length !== 6) {
        newErrors.otp = ERROR_MESSAGES.OTP_LENGTH;
      }

      if (!formData.newPassword) {
        newErrors.newPassword = ERROR_MESSAGES.PASSWORD_REQUIRED;
      } else if (formData.newPassword.length < 8) {
        newErrors.newPassword = ERROR_MESSAGES.PASSWORD_LENGTH;
      }

      if (formData.newPassword !== formData.confirmPassword) {
        newErrors.confirmPassword = ERROR_MESSAGES.PASSWORD_MISMATCH;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, step]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
    // Clear any API errors when user makes changes
    if (error) setError("");
  };

  // Send OTP
  const handleSendOtp = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    setError("");

    try {
      // Add your actual API call here
      await UserController.sendOtpToResetPassword({ email: formData.email });
      setStep(2);
      setSuccess("OTP sent successfully! Please check your email.");
    } catch (err) {
      setError(
        err.response?.data?.message || "Failed to send OTP. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Reset password
  const handleResetPassword = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    setError("");

    try {
      // Add your actual API call here
      await UserController.resetPassword({
        email: formData.email,
        otp: formData.otp,
        newPassword: formData.newPassword,
      });
      setSuccess("Password reset successfully!");

      // Reset form after successful password reset
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Handle modal close
  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setError("");
    setSuccess("");
    setStep(1);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Error and Success Messages */}
        {error && (
          <Alert variant="danger" className="mb-3">
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" className="mb-3">
            {success}
          </Alert>
        )}

        <Form>
          {step === 1 ? (
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label>OTP Code</Form.Label>
                <Form.Control
                  type="text"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  placeholder="Enter 6-digit OTP"
                  maxLength={6}
                  isInvalid={!!errors.otp}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.otp}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  placeholder="Enter new password"
                  isInvalid={!!errors.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm new password"
                  isInvalid={!!errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={step === 1 ? handleSendOtp : handleResetPassword}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Processing...
            </>
          ) : step === 1 ? (
            "Send OTP"
          ) : (
            "Reset Password"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
