import React from "react";
import Spinner from "react-bootstrap/Spinner";

function BtnLoader(props) {
  return (
    <div
      style={
        props.style || {
          minHeight: "25px",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center"
        }
      }
    >
      <Spinner
        animation={props.animation ? props.animation : "border"}
        variant={props.variant ? props.variant : ""}
        size={props.size ? props.size : "sm"}
      />
    </div>
  );
}

export default BtnLoader;
