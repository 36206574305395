import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";
import { HttpClient } from "../controllers/HttpClient";

const initialState = {
  token: null,
  role: null,
  subRole: null,

  // loading
  isLoginLoading: false,
  isUserListLoading: false,
  isApproveSubadminLoading: true,
};

// login
export const adminLogin = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.login(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// society admin login
export const societyAdminLogin = createAsyncThunk(
  "societyAdminLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.societyAdminLogin(data);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// user management
export const getUserListAccordingToRole = createAsyncThunk(
  "getUserListAccordingToRole",
  async (datas, { rejectWithValue }) => {
    try {
      const response = await UserController.getUserListAccordingToRole(
        datas?.role,
        datas?.subrole,
        datas?.token
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveSubAdmin = createAsyncThunk(
  "approveSubadmin",
  async (datas, { rejectWithValue }) => {
    try {
      const response = await UserController.approveSubadmin(
        datas?.data,
        datas.token
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Fetch backed-up users
export const getBackedUpUsers = createAsyncThunk(
  "archives/getBackedUpUsers",
  async ({ token }, thunkAPI) => {
    try {
      const response = await HttpClient.apiCaller(
        "v1/users/backed-up-users",
        "GET",
        {},
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch migration logs
export const getMigrationLogs = createAsyncThunk(
  "archives/getMigrationLogs",
  async ({ token }, thunkAPI) => {
    try {
      const response = await HttpClient.apiCaller(
        "v1/archive/migration-logs",
        "GET",
        {},
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch deletion logs
export const getDeletionLogs = createAsyncThunk(
  "archives/getDeletionLogs",
  async ({ token }, thunkAPI) => {
    try {
      const response = await HttpClient.apiCaller(
        "v1/archive/deletion-logs",
        "GET",
        {},
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Restore user from backup
export const restoreUser = createAsyncThunk(
  "archives/restoreUser",
  async ({ backupId, token }, thunkAPI) => {
    try {
      const response = await HttpClient.apiCaller(
        `v1/user/restore-user/${backupId}`,
        "POST",
        {},
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload.role;
      state.subRole = action.payload.subRole;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      state.role = null;
      state.subRole = null;
      state.isLoginLoading = false;
    },
  },
  extraReducers: (builder) => {
    // Admin login case
    builder.addCase(adminLogin.pending, (state) => {
      state.isLoginLoading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isLoginLoading = false;
      state.token = action.payload?.token;
      state.user = action.payload?.user;
      state.role = action.payload?.user?.role;
      state.subRole = action.payload?.user?.subRole || null;
    });
    builder.addCase(adminLogin.rejected, (state) => {
      state.isLoginLoading = false;
    });

    // Society Admin login case
    builder.addCase(societyAdminLogin.pending, (state) => {
      state.isLoginLoading = true;
    });
    builder.addCase(societyAdminLogin.fulfilled, (state, action) => {
      state.isLoginLoading = false;

      // Validate role and subrole for society admin
      const isValidSocietyAdmin =
        action.payload?.user?.role === "SUBADMIN" &&
        action.payload?.user?.subRole === "SOCIETY";

      if (isValidSocietyAdmin) {
        state.token = action.payload?.token;
        state.user = action.payload?.user;
        state.role = action.payload?.user?.role;
        state.subRole = action.payload?.user?.subRole;
      } else {
        // Don't set any state if validation fails
        state.token = null;
        state.user = null;
        state.role = null;
        state.subRole = null;
      }
    });

    builder.addCase(societyAdminLogin.rejected, (state) => {
      state.isLoginLoading = false;
      // Clear any existing state on rejection
      state.token = null;
      state.user = null;
      state.role = null;
      state.subRole = null;
    });

    //get user list case
    builder.addCase(getUserListAccordingToRole.pending, (state, action) => {
      state.isUserListLoading = true;
    });
    builder.addCase(getUserListAccordingToRole.fulfilled, (state, action) => {
      state.isUserListLoading = false;
    });
    builder.addCase(getUserListAccordingToRole.rejected, (state, action) => {
      state.isUserListLoading = false;
    });

    // approve subadmin case
    builder.addCase(approveSubAdmin.pending, (state, action) => {
      state.isApproveSubadminLoading = true;
    });
    builder.addCase(approveSubAdmin.fulfilled, (state, action) => {
      state.isApproveSubadminLoading = false;
    });
    builder.addCase(approveSubAdmin.rejected, (state, action) => {
      state.isApproveSubadminLoading = false;
    });
  },
});

export const { logout, setToken, setRole } = userSlice.actions;
export default userSlice.reducer;
