import React from "react";
import "./LogoutModal.css";

const LogoutModal = ({ show, handleClose, handleLogout }) => {
  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      id="logoutModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="logoutModalTitle"
      aria-hidden={!show}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content logout-modal-content">
          <div className="modal-content-outline">
            <div className="modal-header" style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
              <h5 className="modal-title" id="logoutModalTitle">
                Are you sure you want to logout?
              </h5>
            </div>
            <div className="modal-body">
              Please confirm if you wish to log out of your account.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary logout-modal-btn-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger logout-modal-btn-logout"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
