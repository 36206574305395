import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import Utility from "../../../../../utils/Utility";
import { useSelector } from "react-redux";
import { UserController } from "../../../../../Redux/controllers/UserController";

export default function DeleteModal({ show, onHide, user, onDelete }) {
  const [includeFamily, setIncludeFamily] = useState(true);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [loadingFamilyMembers, setLoadingFamilyMembers] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.isHeadOfFamily) {
      fetchFamilyMembers();
    }
  }, [user]);

  const fetchFamilyMembers = async () => {
    setLoadingFamilyMembers(true);
    try {
      const { data } = await UserController.getFamilyMembersByHeadId(token, user._id);
      setFamilyMembers(data?.data?.familyMembers || []);
    } catch (error) {
      Utility.eToast("Failed to fetch family members");
    } finally {
      setLoadingFamilyMembers(false);
    }
  };

  const handleDeleteFamily = async () => {
    setDeleteLoading(true);
    try {
      await UserController.deleteFamily(token, {
        familyHeadId: user._id,
        includeFamily: includeFamily,
      });
      Utility.sToast("Family deleted successfully!");
      onDelete();
    } catch (error) {
      Utility.eToast("Failed to delete family");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteResident = async () => {
    setDeleteLoading(true);
    try {
      await UserController.deleteResident(token, user._id);
      Utility.sToast("Resident deleted successfully!");
      onDelete();
    } catch (error) {
      Utility.eToast("Failed to delete resident");
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={user?.firstName + " " + user?.lastName} readOnly />
        </Form.Group>

        {user?.isHeadOfFamily ? (
          <>
            <Form.Check
              type="checkbox"
              label="Include all family members"
              checked={true}
              style={{ marginLeft: "15px", marginTop: "10px" }}
            />
            {includeFamily && (
              <>
                {loadingFamilyMembers ? (
                  <Spinner animation="border" />
                ) : (
                  familyMembers.map((member, index) => (
                    <div key={member._id} className="family-list-row">
                      <div>
                        {`${index + 1}. ${member.firstName} ${member.lastName}`}{" "}
                        {member.isHeadOfFamily ? "[ Head ]" : "[ Member ]"}
                      </div>
                    </div>
                  ))
                )}
              </>
            )}
          </>
        ) : (
          <p>The user is not a family head, hence deleting individually.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="danger"
          disabled={deleteLoading}
          onClick={user?.isHeadOfFamily ? handleDeleteFamily : handleDeleteResident}
        >
          {deleteLoading ? (
            <>
              Deleting <Spinner animation="border" size="sm" />
            </>
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
