import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "../../../utils/Utility";

import Loader from "./../../../components/Loader/Loader";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "../../../utils/Excel";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function Maintenance() {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);

  const getMaintenanceData = async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getMaintenanceDetails(
        token,
        pagination.pageIndex + 1,
        pagination.pageSize
      );
      setMaintenanceData(data?.data?.maintenanceRecords || []);
      setTotalPages(data?.data?.paginationInfo?.totalPages || 1);
      setRowCount(data?.data?.paginationInfo?.totalDocs || 1);
    } catch (error) {
      if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast(
          "Unable to get maintenance details ! Please try again later."
        );
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceData();
  }, [pagination]);

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getMaintenanceData();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sl No.</span>,
        size: 20,
      },
      {
        accessorKey: "Name",
        header: "Name",
        size: 100,
      },
      {
        accessorKey: "Apartment Name",
        header: "Apartment Name",
        size: 100,
      },
      {
        accessorKey: "Flat No",
        header: "Flat No.",
        size: 50,
      },
      {
        accessorKey: "Date",
        header: "Date",
        size: 50,
      },
      {
        accessorKey: "Amount",
        header: "Amount",
        size: 50,
      },
    ],
    []
  );
  const data = useMemo(
    () =>
      maintenanceData?.map((item, i) => ({
        srno: (
          <span className="srnooo">
            {pagination.pageIndex * pagination.pageSize + i + 1}
          </span>
        ),
        Name: (
          <span className="nameeee">
            {item?.headOfFamily?.firstName + " " + item?.headOfFamily?.lastName}
          </span>
        ),
        "Apartment Name":
          // <div className="d-flex">
          // <Button disabled className="v-button">
          item?.society?.societyName || "N/A",
          // </Button>
          // </div>
        "Flat No":
          item?.apartmentNumber || "N/A",
          // <div className="d-flex">
          //   {item?.apartmentNumber ? (
          //     <Button disabled className="v-button">
          //       {item?.apartmentNumber}
          //     </Button>
          //   ) : (
          //     "N/A"
          //   )}
          // </div>
        Date: (
          <span className="nameeee">
            {Utility.formatDate(item?.createdAt, false)}
          </span>
        ),
        Amount: (
          <span className="nameeee">
            {Utility.formatMoney(item?.maintenanceAmount)}
          </span>
        ),
      })),
    [maintenanceData]
  );
  const handleExport = async () => {
    try {
      setExportLoading(true);
      const response = await UserController.getMaintenanceDetails(token);
      let res = response?.data?.data?.maintenanceRecords?.map((item) => ({
        Name:
          item?.headOfFamily?.firstName + " " + item?.headOfFamily?.lastName,
        "Apartment Name": item?.society?.societyName || "N/A",
        "Flat No": item?.apartmentNumber || "N/A",
        Date: Utility.formatDate(item?.createdAt, false),
        Amount: item?.maintenanceAmount
          ? Utility.formatMoney(item?.maintenanceAmount)
          : "N/A",
      }));
      exportToExcel(res, "maintenance");
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setExportLoading(false);
    }
  };
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
        <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Maintenance Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>

        {exportLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <ExportButton onClick={handleExport} />
        )}

        <Row>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              enableCustomPagination={true}
              rowCount={rowCount}
              pagination={pagination}
              setPagination={setPagination}
              pageCount={totalPages}
            />
          )}
        </Row>
      </Container>
    </>
  );
}
