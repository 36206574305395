import React, { useState, useCallback } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import "./AddMaintenanceModal.css";
import { UserController } from "../../../../Redux/controllers/UserController";
import Utility from "../../../../utils/Utility";
import { useSelector } from "react-redux"; // To get user token and other details

const AddMaintenanceModal = ({ show, handleClose, getMaintenanceData }) => {
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [maintenanceAmount, setMaintenanceAmount] = useState(""); // Keep it as a string initially
  const [headOfFamily, setHeadOfFamily] = useState(null);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  // Getting token and society details from redux state
  const { token, user } = useSelector((state) => state.user);
  const societyIdOfSocietyAdmin = user?.societyDetails?.society?._id;

  // Fetch family details based on the apartment number and societyId
  const fetchFamilyDetails = useCallback(async () => {
    if (!apartmentNumber.trim()) {
      Utility.eToast("Apartment number is required.");
      return;
    }
    if (!maintenanceAmount.trim() || isNaN(parseFloat(maintenanceAmount)) || parseFloat(maintenanceAmount) <= 0) {
      Utility.eToast("Please enter a valid maintenance amount.");
      return;
    }

    setLoading(true);
    try {
      const response = await UserController.getFamilyMembersByApartment(
        token,
        societyIdOfSocietyAdmin,
        apartmentNumber
      );
  
      const residents = response?.data?.data?.residents;

      if (residents && residents.length > 0) {
        let head = residents.find((member) => member.isHeadOfFamily === true);
        
        if (head) {
          setHeadOfFamily(head);
          Utility.sToast(`Head of Family found: ${head.firstName} ${head.lastName}`);
        } else {
          Utility.eToast("No head of family found for this apartment");
        }
      } else {
        Utility.eToast("No residents found for this apartment");
      }
    } catch (error) {
      Utility.eToast("Error fetching family details!");
    } finally {
      setLoading(false);
    }
  }, [apartmentNumber, maintenanceAmount, token, societyIdOfSocietyAdmin]);
  
  // Add new maintenance record
  const addMaintenance = async () => {
    if (!apartmentNumber.trim()) {
      Utility.eToast("Apartment number is required.");
      return;
    }
    if (!maintenanceAmount.trim() || isNaN(parseFloat(maintenanceAmount)) || parseFloat(maintenanceAmount) <= 0) {
      Utility.eToast("Please enter a valid maintenance amount.");
      return;
    }
    if (!date.trim()) {
      Utility.eToast("Maintenance due date is required.");
      return;
    }

    if (headOfFamily) {
      setLoading(true);
      const data = {
        society: societyIdOfSocietyAdmin,
        apartmentNumber,
        headOfFamily: headOfFamily?.id,
        maintenanceAmount: parseFloat(maintenanceAmount), // Convert to float before submission
        maintenancePeriod: {
          month: new Date(date).getMonth() + 1,
          year: new Date(date).getFullYear(),
        },
      };
      try {
        await UserController.addMaintenance(token, data);
        getMaintenanceData(); // Refresh data after adding maintenance
        handleClose(); // Close the modal
        Utility.sToast("Maintenance added successfully!");
      } catch (error) {
        Utility.eToast("Error adding maintenance!");
      } finally {
        setLoading(false);
      }
    } else {
      Utility.eToast("Please fetch family details first.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Maintenance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Apartment Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Apartment Number"
            value={apartmentNumber}
            onChange={(e) => setApartmentNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Maintenance Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Amount"
            value={maintenanceAmount} // Directly bind the value
            onChange={(e) => setMaintenanceAmount(e.target.value)} // Use input as a string initially
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Maintenance Due Date</Form.Label>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Form.Group>

        {headOfFamily && (
          <Form.Group>
            <Form.Label>Head of Family</Form.Label>
            <Form.Control
              readOnly
              value={`${headOfFamily.firstName} ${headOfFamily.lastName}`}
            />
          </Form.Group>
        )}

        {loading && <Spinner animation="border" />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={headOfFamily ? addMaintenance : fetchFamilyDetails}
        >
          {headOfFamily ? "Add Maintenance" : "Fetch Family Details"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMaintenanceModal;
