import { HttpClient } from "./HttpClient";

// regular system admin login
const login = (data) => {
  let url = "admin/login";
  return HttpClient.apiCaller(url, "POST", data, null);
};

// society admin login
const societyAdminLogin = (data) => {
  let url = "v1/user/login";
  return HttpClient.apiCaller(url, "POST", data, null);
};

// society / apartments

const getSociety = (token) => {
  let url = "society/get";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const getResidents = (token) => {
  let url = "admin/get-all-user?role=RESIDENT";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getSubAdmins = (token) => {
  let url = "admin/get-all-user?role=SUBADMIN";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getEmployees = (token) => {
  let url = "admin/get-all-user?role=EMPLOYEE";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const addSociety = (data, token) => {
  let url = "society/add";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const getNotices = (token, page, limit) => {
  let url = `notice?page=${page}&limit=${limit}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

const getSocietyAdminNotices = (token, page, limit) => {
  let url = `notice/user?page=${page}&limit=${limit}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

const addNotice = (data, token) => {
  let url = "notice";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const deleteNotice = (noticeId, token) => {
  let url = "notice/" + noticeId;
  return HttpClient.apiCallerUsingV1(url, "DELETE", {}, token);
};
const getMaintenanceDetails = (token, page = 1, limit = 10000) => {
  let url = `maintenance?page=${page}&limit=${limit}`;

  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

const getSocietyAdminMaintenanceDetails = (
  token,
  page = 1,
  limit = 1000,
  societyId
) => {
  let url = `maintenance/?society=${societyId}&page=${page}&limit=${limit}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// const deleteSociety = (token) => {
//   let url = "society/add";
//   return HttpClient.apiCallerUsingV1(url, "POST", null, token);
// };

// user management
const getUserListAccordingToRole = (role = "", subrole = "", token) => {
  let url = "";
  if (subrole) {
    url = `admin/get-all-user?role=${role}&subRole=${subrole}`;
  } else {
    url = `admin/get-all-user?role=${role}`;
  }
  return HttpClient.apiCaller(url, "GET", null, token);
};

// approve subadmin
const approveSubadmin = (data, token) => {
  let url = "user/is-approve";
  return HttpClient.apiCallerUsingV1(url, "PATCH", data, token);
};

const getAllVisitors = (token) => {
  let url = "visitor";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const getFamilyMembers = (token, userId) => {
  let url = "user/get-my-family-member?id=" + userId;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const getLegal = (token) => {
  let url = "legal";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const addLegal = (token, data) => {
  let url = "legal";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const deleteLegal = (token, legalId) => {
  let url = "legal/" + legalId;
  return HttpClient.apiCallerUsingV1(url, "DELETE", {}, token);
};
const editLegal = (token, legalId, data) => {
  let url = "legal/" + legalId;
  return HttpClient.apiCallerUsingV1(url, "PUT", data, token);
};
const getComplaints = (token) => {
  let url = "complaint";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

const societyToggleStatus = (token, data) => {
  let url = "society/toggle-status";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const userToggleStatus = (token, data) => {
  let url = "user/toggle-status";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};

const blockSocietyUsers = (token, data) => {
  let url = "user/block-by-society";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const unBlockSocietyUsers = (token, data) => {
  let url = "user/unblock-by-society";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};
const getEmergencyDetails = (token, page = 1, limit = 10000) => {
  let url = `emergency?page=${page}&limit=${limit}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const getVisitorLogDetails = (token, visitorId) => {
  let url = `visitor/logs?visitorId=${visitorId}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const getStatsData = (token, societyId) => {
  let url;
  if (societyId) {
    url = "user/society-statistics?societyId=" + societyId;
  } else {
    url = "user/society-statistics";
  }
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Approve maintenance record
const approveMaintenance = (token, maintenanceId) => {
  let url = `maintenance/${maintenanceId}/approve`;
  return HttpClient.apiCallerUsingV1(url, "PATCH", {}, token);
};

// Delete maintenance record
const deleteMaintenance = (token, maintenanceId) => {
  let url = `maintenance/${maintenanceId}`;
  return HttpClient.apiCallerUsingV1(url, "DELETE", {}, token);
};
const getFamilyMembersByApartment = (token, societyId, apartmentNumber) => {
  const url = `user/by-apartment?societyId=${societyId}&apartmentNumber=${apartmentNumber}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Add new maintenance record
const addMaintenance = (token, data) => {
  const url = "maintenance";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};

// Family Migration APIs
const migrateFamily = (token, data) => {
  let url = "user/migrate-family";
  return HttpClient.apiCallerUsingV1(url, "PATCH", data, token);
};

const migrateResident = (token, data) => {
  let url = "user/migrate-resident";
  return HttpClient.apiCallerUsingV1(url, "PATCH", data, token);
};

const getFamilyMembersByHeadId = (token, headId) => {
  let url = `user/get-family-members-by-head-id?headId=${headId}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Society management
const getSocietiesForDropdown = (token) => {
  let url = "society/get?isActive=true";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// New Deletion APIs
const deleteResident = (token, residentId) => {
  let url = `user/delete-resident/${residentId}`;
  return HttpClient.apiCallerUsingV1(url, "DELETE", {}, token);
};

const deleteFamily = (token, data) => {
  let url = "user/delete-family";
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};

// Fetch backed-up users
const getBackedUpUsers = (token) => {
  let url = "user/backed-up-users";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Fetch migration logs
const getMigrationLogs = (token) => {
  let url = "user/migration-logs";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Fetch deletion logs
const getDeletionLogs = (token) => {
  let url = "user/deletion-logs";
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};

// Restore user from backup
const restoreUser = (backupId, token) => {
  let url = `user/restore-user/${backupId}`;
  return HttpClient.apiCallerUsingV1(url, "POST", {}, token);
};

// Restore user from backup
const deleteBackup = (backupId, token) => {
  let url = `user/delete-backup/${backupId}`;
  return HttpClient.apiCallerUsingV1(url, "DELETE", {}, token);
};

// Fetch family members by society ID and apartment number
const getFamilyMembersBySocietyIdApartmentNumber = (
  token,
  societyId,
  apartmentNumber
) => {
  const url = `user/family-members-by-soc-app-num?societyId=${societyId}&apartmentNumber=${apartmentNumber}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const sendOtpToResetPassword = (data) => {
  const url = "user/request-password-reset";
  return HttpClient.apiCallerUsingV1(url, "POST", data, null);
};
const resetPassword = (data) => {
  const url = "user/reset-password";
  return HttpClient.apiCallerUsingV1(url, "POST", data, null);
};
const deleteEmployee = (token, empId) => {
  const url = `admin/delete-employee/${empId}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
const deleteSubAdmin = (token, subAdminId) => {
  const url = `admin/delete-subadmin/${subAdminId}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
const getSocietyUserCount = (token, societyId) => {
  const url = `society/user-count/${societyId}`;
  return HttpClient.apiCallerUsingV1(url, "GET", {}, token);
};
const deleteSociety = (token, data) => {
  const url = `society/delete-society`;
  return HttpClient.apiCallerUsingV1(url, "POST", data, token);
};

export const UserController = {
  // authentication
  login,
  societyAdminLogin,
  // society CRUD
  getSociety,
  addSociety,
  getResidents,
  getUserListAccordingToRole,
  approveSubadmin,
  getSubAdmins,
  getEmployees,
  addNotice,
  getNotices,
  deleteNotice,
  getMaintenanceDetails,
  getAllVisitors,
  getFamilyMembers,
  addLegal,
  deleteLegal,
  getLegal,
  editLegal,
  getComplaints,
  societyToggleStatus,
  blockSocietyUsers,
  userToggleStatus,
  getStatsData,
  unBlockSocietyUsers,
  getEmergencyDetails,
  getVisitorLogDetails,
  // society admin apis
  getSocietyAdminMaintenanceDetails,
  getSocietyAdminNotices,
  // New APIs for Maintenance
  approveMaintenance,
  deleteMaintenance,
  getFamilyMembersByApartment,
  addMaintenance,
  // new apis for the migration system
  migrateFamily,
  migrateResident,
  getFamilyMembersByHeadId,
  getSocietiesForDropdown,
  // New Deletion APIs
  deleteResident,
  deleteFamily,
  // New archive APIs for logs
  getBackedUpUsers,
  getMigrationLogs,
  getDeletionLogs,
  restoreUser,
  deleteBackup,
  // get family details
  getFamilyMembersBySocietyIdApartmentNumber,
  sendOtpToResetPassword,
  resetPassword,
  deleteEmployee,
  deleteSubAdmin,
  getSocietyUserCount,
  deleteSociety,
};
