import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  approveSubAdmin,
  getUserListAccordingToRole,
} from "../../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../utils/Utility";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Loader from "../../../components/Loader/Loader";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import VerifyFamilyModal from "./components/VerifyModal/VerifyFamilyModal";
import MigrationModal from "./components/MigrationModal/MigrationModal";
import DeleteModal from "./components/DeletionModal/DeletionModal";
import BtnLoader from "../../../components/Loader/BtnLoader";

const column = [
  {
    accessorKey: "srno",
    header: <span className="custom-header">Sr No.</span>,
    size: 50,
  },
  {
    accessorKey: "name",
    header: "Name",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
    size: 50,
  },
  {
    accessorKey: "email",
    header: "Email Id",
    size: 50,
  },
  {
    accessorKey: "apartmentNumber",
    header: "Apartment Number",
    size: 50,
  },
  {
    accessorKey: "societyName",
    header: "Society Name",
    size: 50,
  },
  {
    accessorKey: "designation",
    header: "Designation",
    size: 50,
  },
  {
    accessorKey: "btn",
    header: "Action",
    size: 50,
  },
  {
    accessorKey: "migrate",
    header: "Migrate",
    size: 50,
  },
  {
    accessorKey: "delete",
    header: "Delete",
    size: 50,
  },
];

export default function Families() {
  const dispatch = useDispatch();
  const { token, isUserListLoading, user } = useSelector((state) => state.user);

  const loggedInSocietyId = user?.societyDetails?.society?._id;

  const [userListAccordingToRole, setUserListAccordingToRole] = useState([]);
  const [selectedFamilyToVerify, setSelectedFamilyToVerify] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectedUserToMigrate, setSelectedUserToMigrate] = useState(null);
  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false); // Refresh loading state

  useEffect(() => {
    getUserListAccordingToRoleFun();
  }, []);

  const getUserListAccordingToRoleFun = async () => {
    return dispatch(
      getUserListAccordingToRole({
        role: "RESIDENT",
        token: token,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const allUsers = res?.data?.users;
        const filteredUsers = allUsers
          .filter((user) => user?.societyDetails?.society?._id === loggedInSocietyId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by most recently created at the top
        setUserListAccordingToRole({
          totalUser: filteredUsers.length,
          users: filteredUsers,
        });
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Get User List ! Please try again later.");
        }
      });
  };
  
  
  const approveFamilyRequestFun = async (family) => {
    setSelectedFamilyToVerify(family._id);
    const data = { id: family._id, isApproved: true };

    await dispatch(approveSubAdmin({ data: data, token: token }))
      .then(unwrapResult)
      .then(() => {
        getUserListAccordingToRoleFun();
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Approve Family ! Please try again later.");
        }
      })
      .finally(() => setSelectedFamilyToVerify(null));
  };

  const data =
    userListAccordingToRole?.users?.length > 0 &&
    userListAccordingToRole?.users.map((item, i) => ({
      srno: <span className="srnooo">{i + 1}</span>,
      name: item?.firstName + " " + item?.lastName || "N/A",
      phoneNumber: item?.phoneNumber || "N/A",
      email: item?.email || "N/A",
      apartmentNumber: item?.societyDetails?.apartmentNumber || "N/A",
      societyName: item?.societyDetails?.society?.societyName || "N/A",
      designation: item?.isHeadOfFamily ? "Head" : "Member" || "N/A",
      btn: (
        <Button
          className="verify-btn"
          variant={item?.isApproved ? "success" : "success"}
          disabled={selectedFamilyToVerify === item?._id || item?.isApproved}
          onClick={() => {
            setSelectedFamilyToVerify(item);
            setShowVerifyModal(true);
          }}
        >
          {item?.isApproved ? "Verified" : "Verify"}
        </Button>
      ),
      migrate: (
        <Button
          className="migrate-btn"
          disabled={!item?.isApproved} // Disable if not approved
          onClick={() => {
            setSelectedUserToMigrate(item);
            setShowMigrationModal(true);
          }}
        >
          Migrate
        </Button>
      ),
      delete: (
        <Button
          className="delete-btn btn-danger"
          disabled={!item?.isApproved} // Disable if not approved
          onClick={() => {
            setSelectedUserToDelete(item);
            setShowDeletionModal(true);
          }}
        >
          Delete
        </Button>
      ),
    }));

  const handleDelete = () => {
    getUserListAccordingToRoleFun();
    setShowDeletionModal(false);
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await getUserListAccordingToRoleFun(); // Ensure this is awaited
    } catch (err) {
    } finally {
      setRefreshLoading(false);
    }
  };

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Families Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
            total={userListAccordingToRole?.totalUser}
          />
        </Row>
        <Row style={{marginTop: "1rem"}}>
          <Col style={{fontSize: "1.2rem", color: "gray"}}>Family Heads & Members</Col>
          <hr />
        </Row>
        {isUserListLoading ? (
          <Loader />
        ) : (
          <Row>
            <DataTable columns={column} data={data} />
          </Row>
        )}
      </Container>

      <VerifyFamilyModal
        show={showVerifyModal}
        onHide={() => setShowVerifyModal(false)}
        family={selectedFamilyToVerify}
        onApprove={() => {
          approveFamilyRequestFun(selectedFamilyToVerify);
          setShowVerifyModal(false);
        }}
      />

      <MigrationModal
        show={showMigrationModal}
        onHide={() => setShowMigrationModal(false)}
        user={selectedUserToMigrate}
        onMigrate={() => {
          getUserListAccordingToRoleFun();
          setShowMigrationModal(false);
        }}
      />

      <DeleteModal
        show={showDeletionModal}
        onHide={() => setShowDeletionModal(false)}
        user={selectedUserToDelete}
        onDelete={handleDelete}
      />
    </>
  );
}
