import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./ActionButton.css";

const ActionButton = ({ id, onClick, disabled, title, icon, color, width }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Inline styles
  const buttonStyle = {
    borderColor: color,
    color: isHovered ? "white" : color,
    width: width,
    backgroundColor: isHovered ? color : "white",
    transition: "0.3s ease-in-out",
    fontWeight: "500",
  };

  return (
    <Button
      id={id}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle}
      className="action-button"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {title} {icon && React.cloneElement(icon, { id: "button-Icon" })}
    </Button>
  );
};

export default ActionButton;
