import React from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
// import { FaSearch } from "react-icons/fa";
import "./TopBar.css";
// import { MdOutlineDateRange } from "react-icons/md";
// import { CiFilter } from "react-icons/ci";
// import { LuLayoutDashboard } from "react-icons/lu";
// import { FaList } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";

export default function TopBar(props) {
  return (
    <Container className="pt-5 ">
      <Row>
        <Col id="xyz">
          {props.name}
          {/* {props?.total && props.total > 0  && `(${props.total})`} */}
        </Col>
      </Row>
      {/* <Row id="xyz-row">
        <Col md={7} xs={12}>
          <Row>
            <Col md={7} xs={12} id="search-placeholder">
              <span>
                <CiSearch style={{ fontSize: "22px" }} />
              </span>
              <span id="searchicon">
                <input type="text" placeholder="search..." id="searchbar" />
              </span>
            </Col>
            <Col>
              <Button id="filter-button-filter">
                {" "}
                <CiFilter id="filter-icon" /> Filter{" "}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={5} xs={12} id="icon-col-icon">
          <div id="icon-div">
            <span id="listicon">
              <FaList />
            </span>
            <span id="listicon">
              <LuLayoutDashboard />
            </span>
            <span id="listicon">
              <MdOutlineDateRange />
            </span>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
}
