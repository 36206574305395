import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function MenuItem({ to, icon: Icon, label, active, onClick }) {
  return (
    <Row
    as={Link}
    to={to}
    onClick={onClick}
    className={active === to ? "sbm6" : "sbm4"}
  >
    <Col xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} xxl={10} className="sbm9">
      <span className="sbm5">
        <Icon className={active === to ? "sbm12" : "sbm11"} />
      </span>
      <span>{label}</span>
    </Col>
  </Row>
  )
}
