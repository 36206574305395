import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import PageNotFoundImage from "../../assets/pageNotFound.png";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.css";

export default function PageNotFound({ page }) {
  const navigate = useNavigate();

  return (
    <Container className="page-not-found-container">
      <Row className="page-not-found-row">
        <Col className="page-not-found-content">
          <img
            src={PageNotFoundImage}
            alt="page-not-found"
            className="page-not-found-img"
          />
          <h3 className="page-not-found-title">Page Not Found</h3>
          <Button
            variant="success"
            onClick={() => navigate("/")}
            className="page-not-found-btn"
          >
            Back to {page === "login" ? "Login" : "Dashboard"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
