import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { UserController } from "../../../../../Redux/controllers/UserController";
import Utility from "../../../../../utils/Utility";
import { useSelector } from "react-redux";

export default function ShowFamilyMembersModal({ show, onHide, userDetails }) {
  const [familyMembers, setFamilyMembers] = useState([]);
  const [loadingFamilyMembers, setLoadingFamilyMembers] = useState(false);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (userDetails?.flatNo && userDetails?.societyId) {
      fetchFamilyMembersBySocietyAndApartment();
    }
  }, [userDetails]);

  const fetchFamilyMembersBySocietyAndApartment = async () => {
    setLoadingFamilyMembers(true);
    try {
      const { data } = await UserController.getFamilyMembersBySocietyIdApartmentNumber(
        token,
        userDetails.societyId, 
        userDetails.flatNo      
      );
      setFamilyMembers(data?.data?.residents || []);
    } catch (error) {
      Utility.eToast("Failed to fetch family members");
    } finally {
      setLoadingFamilyMembers(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Family Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingFamilyMembers ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : familyMembers.length > 0 ? (
          familyMembers.map((member, index) => (
            <div key={member._id} className="family-list-row">
              <div>{`${index + 1}. ${member.firstName} ${member.lastName}`}{"   "}{ member.isHeadOfFamily ? "[ Head ]" : "[ Member ]"}</div>
            </div>
          ))
        ) : (
          <p>No family members found.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
