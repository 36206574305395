import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { UserController } from "../../../../../Redux/controllers/UserController";
import Utility from "../../../../../utils/Utility";

const DeleteSocietyModal = ({
  show,
  onHide,
  selectedSociety,
  token,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [countLoading, setCountLoading] = useState(false);

  useEffect(() => {
    if (show && selectedSociety?._id) {
      fetchUserCount();
    }
  }, [show, selectedSociety]);

  const fetchUserCount = async () => {
    setCountLoading(true);
    try {
      const response = await UserController.getSocietyUserCount(
        token,
        selectedSociety._id
      );

      console.log("first", response);
      setUserCount(response?.data?.data?.count || 0);
    } catch (error) {
      console.error("Error fetching user count:", error);
      Utility.eToast("Failed to fetch associated users count");
    } finally {
      setCountLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const deleteData = {
        societyId: selectedSociety._id,
      };

      const response = await UserController.deleteSociety(token, deleteData);
      Utility.sToast(response?.data?.message || "Society deleted successfully");
      onSuccess();
      onHide();
    } catch (error) {
      Utility.eToast(
        error?.response?.data?.message || "Failed to delete society"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Society</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete{" "}
          <strong>{selectedSociety?.societyName}</strong>?
        </p>

        {countLoading ? (
          <div className="d-flex align-items-center">
            <Spinner animation="border" size="sm" className="me-2" />
            <span>Fetching user count...</span>
          </div>
        ) : (
          <div className="alert alert-warning">
            <p className="mb-0">
              This society has <strong>{userCount} associated users</strong>.
              {userCount > 0 &&
                " All associated users will be deleted permanently."}
            </p>
          </div>
        )}

        <p className="text-danger mt-3">
          <strong>Warning:</strong> This action cannot be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          disabled={loading || countLoading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Deleting...
            </>
          ) : (
            "Delete Society"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSocietyModal;
