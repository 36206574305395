import React, { useState } from "react";
import { Button, Col, Row, Spinner, Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Card.css";
import Utility from "../../utils/Utility";
import { RiDeleteBin6Line } from "react-icons/ri";
import { UserController } from "../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const formatContent = (text) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const truncateContent = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit);
  }
  return text;
};

export default function CardComp({
  date,
  title = "",
  content = "",
  noticeId,
  deleted,
  apartmentName,
  apartmentNo,
  sender,
  isComplaint = false,
  status,
}) {
  const [showMore, setShowMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal
  const contentLimit = 150;
  const { token, role } = useSelector((st) => st?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [confirmLoading, setConfirmLoading] = useState(false);

  const deleteNotice = async () => {
    setConfirmTitle("Are You sure to delete notification?");
    setConfirmAction(() => async () => {
      try {
        setConfirmLoading(true);
        setIsLoading(true)
        const { data } = await UserController.deleteNotice(noticeId, token);
        Utility.sToast(data?.message);
        deleted();
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
        setIsLoading(false)
      }
    });
    setShowConfirmModal(true);
  };

  return (
    <>
      <Card
        id="card-row-row"
        className={`position-relative ${isComplaint && "complaint-row"}`}
      >
        {/* Conditional rendering of the delete button based on the role */}
        {!isComplaint && role === "ADMIN" && (
          <>
            {!showMore && (
              <>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="danger"
                    size="md"
                    id="notice-delete-spinner"
                  />
                ) : (
                  <Button
                    variant="danger"
                    id="close-button234"
                    onClick={() => setShowDeleteModal(true)} // Open delete modal
                  >
                    <RiDeleteBin6Line /> Delete
                  </Button>
                )}
              </>
            )}
          </>
        )}

        <Row className="mb-4">
          {isComplaint ? (
            <>
              <Col
                xs={12}
                md={4}
                className="d-flex align-items-center gap-2 my-1"
              >
                <span className="appartment-name234">Sender</span>
                <Button disabled className="v-button">
                  {sender}
                </Button>
              </Col>
              <Col
                xs={12}
                md={4}
                className="d-flex align-items-center gap-2 my-1"
              >
                <span className="appartment-name234">Apartment Name</span>
                <Button disabled className="v-button">
                  {apartmentName}
                </Button>
              </Col>
              <Col
                xs={12}
                md={2}
                className="d-flex align-items-center gap-2 my-1"
              >
                <span className="appartment-name234">Flat No</span>

                <Button disabled className="v-button">
                  {apartmentNo}
                </Button>
              </Col>
              <Col
                xs={12}
                md={2}
                className="button-col d-flex align-items-center justify-content-end my-1"
              >
                {status === "CLOSED" ? (
                  <Button disabled className="vs-button-closed">
                    {status}
                  </Button>
                ) : (
                  <Button disabled className="vs-button-open">
                    {status}
                  </Button>
                )}
              </Col>
            </>
          ) : (
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-end my-1"
            >
              {Utility.formatDate(date)}
            </Col>
          )}
        </Row>
        <Row className="appartment-name234 mb-3">
          <Col
            xs={12}
            className="fs-5  d-flex align-items-center gap-4 apartment-small"
          >
            {title}
          </Col>
        </Row>

        <Row style={{ paddingLeft: "0.3rem", marginTop: "0.5rem" }}>
          <Col id="dear">
            {showMore
              ? formatContent(content)
              : formatContent(truncateContent(content, contentLimit))}
            {!showMore && content.length > contentLimit && "..."}
          </Col>
        </Row>
        <Row>
          <Col>
            {content.length > contentLimit && (
              <Button
                variant="link"
                style={{ color: "#006944", fontWeight: "500" }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </Button>
            )}
          </Col>
        </Row>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this notice?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteNotice}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
