import axios from "axios";
// const API_BASE_URL = "http://192.168.29.167:3030/api/";
// const API_BASE_URL_V1 = "http://192.168.29.167:3030/api/v1/";
// const API_BASE_URL = "https://api-cybernetics-ai.onrender.com/api/";

// prod
export const SOCKET_URL = "https://api.guardx.site:3030";
const API_BASE_URL = "https://api.guardx.site/api/";
const API_BASE_URL_V1 = "https://api.guardx.site/api/v1/";

// dev
// export const SOCKET_URL = "http://localhost:3030";
// const API_BASE_URL = "http://localhost:3030/api/";
// const API_BASE_URL_V1 = "http://localhost:3030/api/v1/";

const apiCaller = (
  uri,
  method = "GET",
  data = {},
  token,
  contentType = null
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL + uri,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const apiCallerUsingV1 = (
  uri,
  method = "GET",
  data = {},
  token,
  contentType = null
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL_V1 + uri,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HttpClient = {
  apiCaller,
  apiCallerUsingV1,
  API_BASE_URL,
  API_BASE_URL_V1,
};
