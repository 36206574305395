import { useEffect, useState } from "react";
import "./SideBarMenu.css";
import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MdClose,
  MdOutlinePeopleAlt,
  MdOutlineSpaceDashboard,
  MdLogout,
} from "react-icons/md";
import { LuArchive } from "react-icons/lu";
import { RiAdminLine } from "react-icons/ri";
import { IoCarSportOutline } from "react-icons/io5";
import { LuBadgePercent } from "react-icons/lu";
import logo1 from "../../assets/dashboard/Guardx.png";
import MenuItem from "./Components/MenuItem/MenuItem";
import { IoMailOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { MdOutlineManageAccounts, MdOutlinePrivacyTip } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { logout } from "../../Redux/Slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import LogoutModal from "./Components/LogoutModal/LogoutModal";
import Loader from "../Loader/Loader";

function SideBarMenu() {
  const expand = "md";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [active, setActive] = useState("/dashboard");
  const { role } = useSelector((state) => state.user);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    setShowLogoutModal(false);
  };

  const menuItems =
    role === "SUBADMIN"
      ? [
          { to: "/dashboard", icon: MdOutlineSpaceDashboard, label: "Dashboard" },
          { to: "/families", icon: MdOutlinePeopleAlt, label: "Families" },
          { to: "/complaints", icon: MdOutlinePeopleAlt, label: "Complaints" },
          { to: "/emergency", icon: HiOutlineLightBulb, label: "Emergency System" },
          { to: "/maintenance", icon: IoCarSportOutline, label: "Maintenance" },
          { to: "/notice", icon: IoMailOutline, label: "Notices" },
          { to: "/archives", icon: LuArchive, label: "Archives" },
          { to: "/profile", icon: RiAdminLine, label: "Profile" },
        ]
      : [
          { to: "/dashboard", icon: MdOutlineSpaceDashboard, label: "Dashboard" },
          { to: "/complaints", icon: MdOutlinePeopleAlt, label: "Complaints" },
          { to: "/emergency", icon: HiOutlineLightBulb, label: "Emergency System" },
          { to: "/employees", icon: MdOutlinePeopleAlt, label: "Employees" },
          { to: "/maintenance", icon: IoCarSportOutline, label: "Maintenance" },
          { to: "/management", icon: FaRegUser, label: "User Manage" },
          { to: "/notice", icon: IoMailOutline, label: "Notice Manage" },
          { to: "/society", icon: TbBuildingSkyscraper, label: "Society Manage" },
          { to: "/visitor", icon: LuBadgePercent, label: "Visitor Manage" },
          { to: "/subadmin", icon: MdOutlineManageAccounts, label: "Sub Admin" },
          { to: "/legal", icon: MdOutlinePrivacyTip, label: "Legal" },
          { to: "/profile", icon: RiAdminLine, label: "Profile" },
        ];

  const { user } = useSelector((state) => state.user);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const getPlaceholderAvatar = () => {
    const firstLetter = user?.firstName?.charAt(0).toUpperCase();
    return (
      <div className="sb-placeholder-avatar">
        <span className="sb-placeholder-avatar-text">{firstLetter}</span>
      </div>
    );
  };

  return (
    <>
      {screenWidth > 768 ? (
        <Container fluid id="sidebar-nav">
          <Navbar key={expand} expand={expand}>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setShowOffcanvas(true)}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              show={showOffcanvas}
              className="sbm3"
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Image
                    src={logo1}
                    alt="logo"
                    className="sbm2"
                    onClick={() => {
                      navigate("/");
                      setShowOffcanvas(false);
                    }}
                  />
                </Offcanvas.Title>
                <span>
                  <MdClose
                    onClick={() => setShowOffcanvas(false)}
                    style={{ height: "1.8rem", width: "1.8rem", cursor: "pointer" }}
                  />
                </span>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container className="sbm15">
                  {screenWidth < 768 ? null : (
                    <Row className="sbm17">
                      <Col xs={12} className="sb-profile-header">
                        <div className="sb-profile-avatar-wrapper">
                          {isImageLoading && user?.profileImage && (
                            <div className="sb-profile-avatar">
                              <Loader
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  minHeight: "auto",
                                }}
                                size="sm"
                                animation="border"
                                variant="success"
                              />
                            </div>
                          )}
                          {user?.profileImage ? (
                            <Image
                              src={user?.profileImage}
                              className={`sb-profile-avatar ${isImageLoading ? "loading" : ""}`}
                              onLoad={() => setIsImageLoading(false)}
                              style={{ display: isImageLoading ? "none" : "block" }}
                            />
                          ) : (
                            getPlaceholderAvatar()
                          )}
                        </div>
                        <div className="sb-user-data">
                          <h2 className="sb-profile-name">
                            {user?.firstName} {user?.lastName}
                          </h2>
                          <p className="sb-profile-apartment">
                            {user?.societyDetails?.society?.societyName}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="sbm14">
                    {menuItems.map((item) => (
                      <MenuItem
                        key={item.to}
                        {...item}
                        active={active}
                        onClick={() => {
                          setShowOffcanvas(false);
                          setActive(item.to);
                        }}
                      />
                    ))}
                    <Row
                      className="logout-row mb-5"
                      style={{ cursor: "pointer" }}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                        onClick={() => setShowLogoutModal(true)}
                      >
                        <span className="sbm5">
                          <MdLogout className="sbm11" />
                        </span>
                        <span>Logout</span>
                      </Col>
                    </Row>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        </Container>
      ) : (
        <Navbar key={expand} expand={expand}>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={() => setShowOffcanvas(true)}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
            show={showOffcanvas}
            className="sbm3"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Image
                  src={logo1}
                  alt="logo"
                  className="sbm2"
                  onClick={() => {
                    navigate("/");
                    setShowOffcanvas(false);
                  }}
                />
              </Offcanvas.Title>
              <span>
                <MdClose
                  onClick={() => setShowOffcanvas(false)}
                  style={{ height: "1.8rem", width: "1.8rem", cursor: "pointer" }}
                />
              </span>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Container className="sbm15">
                <Row className="sbm14">
                  {menuItems.map((item) => (
                    <MenuItem
                      key={item.to}
                      {...item}
                      active={active}
                      onClick={() => {
                        setShowOffcanvas(false);
                        setActive(item.to);
                      }}
                    />
                  ))}
                  <Row className="logout-row" style={{ cursor: "pointer" }}>
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                      onClick={() => setShowLogoutModal(true)}
                    >
                      <span className="sbm5">
                        <MdLogout className="sbm11" />
                      </span>
                      <span>Logout</span>
                    </Col>
                  </Row>
                </Row>
              </Container>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      )}
      <LogoutModal
        show={showLogoutModal}
        handleClose={() => setShowLogoutModal(false)}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default SideBarMenu;
