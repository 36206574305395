import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { BiMessageCheck, BiTrash } from "react-icons/bi";

import "./Employees.css";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  approveSubAdmin,
  getUserListAccordingToRole,
} from "../../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../utils/Utility";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import Loader from "../../../components/Loader/Loader";
import ExportButton from "../../../Globalcomponent/ExportButton/ExportButton";
import { exportToExcel } from "./../../../utils/Excel";
import ActionButton from "../../../Globalcomponent/ActionButtons/ActionButton";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import { UserController } from "../../../Redux/controllers/UserController";
import ConfirmModal from "../../../Globalcomponent/ConfirmModal/ConfirmModal";
import VerifyEmployeeModal from "./components/VerifyEmployeeModal"; // Import the modal
import BtnLoader from "../../../components/Loader/BtnLoader";

const column = [
  {
    accessorKey: "srno",
    header: <span className="custom-header">Sr No.</span>,
    size: 50,
  },
  {
    accessorKey: "name",
    header: "Name",
    enableResizing: true,
    size: 300,
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
    size: 50,
  },
  {
    accessorKey: "email",
    header: "Email Id",
    size: 50,
  },
  {
    accessorKey: "subrole",
    header: "Sub-role",
    size: 50,
  },
  {
    accessorKey: "btn",
    header: "Action",
    size: 50,
  },
];

export default function SubAdmin() {
  const dispatch = useDispatch();
  const { token, isUserListLoading } = useSelector((state) => state.user);
  const [toggleLoading, setToggleLoading] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [userListAccordingToRole, setUserListAccordingToRole] = useState([]);
  // const [selectedEmployeeToVerifyToVerify, setSelectedEmployeeToVerify] =
  //   useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [showVerifySubadminModal, setShowVerifySubadminModal] = useState(false);
  const [selectedEmployeeToVerify, setSelectedEmployeeToVerify] =
    useState(null); // Employee to verify
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [refreshLoading, setRefreshLoading] = useState(false);
  useEffect(() => {
    getUserListAccordingToRoleFun();
  }, [refresh]);

  const getUserListAccordingToRoleFun = () => {
    dispatch(
      getUserListAccordingToRole({
        role: "EMPLOYEE",
        subrole: "GATEMAN",
        token: token,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        const allusers = res?.data?.users;
        setUserListAccordingToRole({
          totalUser: res?.data?.totalUsers,
          users: allusers,
        });
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          Utility.eToast("Network error! Please try again later.");
        } else {
          Utility.eToast("Unable to Get User List ! Please try again later.");
        }
      });
  };
  const approveEmployeeRequestFun = (item) => {
    setConfirmTitle("Are You sure to approve this employee?");
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      setSelectedEmployeeToVerify(item?._id);
      const data = {
        id: item?._id,
        isApproved: true,
      };
      await dispatch(approveSubAdmin({ data: data, token: token }))
        .then(unwrapResult)
        .then((res) => {
          Utility.sToast(res?.data?.message);
          getUserListAccordingToRoleFun();
        })
        .catch((err) => {
          Utility.eToast(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setSelectedEmployeeToVerify("");
          setConfirmLoading(false);
          setShowConfirmModal(false);
        });
    });
    setShowConfirmModal(true);
  };
  const handleToggleStatus = (item) => {
    setConfirmTitle(
      `Are you sure you want to ${
        item?.isActive ? "deactivate" : "activate"
      } sub admin?`
    );
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        const toggleData = {
          userId: item?._id,
          isActive: !item?.isActive,
        };
        const response = await UserController.userToggleStatus(
          token,
          toggleData
        );
        Utility.sToast(response?.data?.message);
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(error?.response?.data?.message || error?.message);
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };
  const column = useMemo(
    () => [
      {
        accessorKey: "srno",
        header: <span className="custom-header">Sr No.</span>,
        size: 50,
      },

      {
        accessorKey: "Name",
        header: "Name",
        enableResizing: true,
        size: 300,
      },
      {
        accessorKey: "Mobile No",
        header: "Phone Number",
        size: 50,
      },
      {
        accessorKey: "Email",
        header: "Email Id",
        size: 50,
      },
      {
        accessorKey: "Apartment Name",
        header: "Apartment Name",
        size: 50,
      },

      {
        accessorKey: "Sub Role",
        header: "Sub-Role",
        size: 50,
      },

      {
        accessorKey: "Status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "btn",
        header: "Action",
        size: 50,
      },
      // {
      //   accessorKey: "view",
      //   header: "Actions",
      //   enableResizing: true,
      //   size: 300,
      // },
    ],
    []
  );
  const data =
    userListAccordingToRole?.users?.length > 0 &&
    userListAccordingToRole?.users.map((item, i) => ({
      srno: <span className="srnooo">{i + 1}</span>,
      Name: item?.firstName + " " + item?.lastName || "N/A",
      "Mobile No": item?.phoneNumber || "N/A",
      Email: item?.email || "N/A",
      "Apartment Name": item?.societyDetails?.society?.societyName || "N/A",
      "Sub Role": item?.subRole || "N/A",
      "Verification Status": item?.isApproved ? "Verified" : "Not Verified",
      "Active Status": item?.isActive ? "Active" : "Inactive",
      Status: (
        <div
          style={{ color: item?.isActive ? "#1a7857" : "red", fontWeight: 500 }}
        >
          {item?.isActive ? "ACTIVE" : "INACTIVE"}
        </div>
      ),
      btn: (
        <div className="d-flex align-items-center gap-3">
          <ActionButton
            title={item?.isApproved ? "VERIFIED" : "VERIFY "}
            color={"#1a7857"}
            width={"5rem"}
            onClick={() => {
              approveEmployeeRequestFun(item);
            }}
            disabled={
              selectedEmployeeToVerify === item?._id || item?.isApproved
            }
          />

          {toggleLoading[item?._id] ? (
            <Spinner animation="border" size="20" color="#1a7857" />
          ) : (
            <>
              {item?.isActive ? (
                <ActionButton
                  title={"De-Activate"}
                  icon={<FiUserX />}
                  color={"rgb(109, 117, 124)"}
                  width={"8rem"}
                  onClick={() => handleToggleStatus(item)}
                />
              ) : (
                <ActionButton
                  title={"Activate"}
                  icon={<FiUserCheck />}
                  color={"#1a7857"}
                  width={"8rem"}
                  onClick={() => handleToggleStatus(item)}
                />
              )}
            </>
          )}

          {/* Add Delete Button */}
          <ActionButton
            title={"Delete"}
            icon={<BiTrash />}
            color={"#dc3545"} // Red color for delete
            width={"6rem"}
            onClick={() => handleDelete(item)}
          />
        </div>
      ),
    }));

  const handleDelete = (item) => {
    setConfirmTitle("Are you sure you want to delete this employee?");
    setConfirmAction(() => async () => {
      setConfirmLoading(true);
      try {
        // Replace this with your actual delete API call
        const response = await UserController.deleteEmployee(token, item?._id);
        Utility.sToast(
          response?.data?.message || "Employee deleted successfully"
        );
        setRefresh((prev) => !prev);
      } catch (error) {
        Utility.eToast(
          error?.response?.data?.message || "Failed to delete employee"
        );
      } finally {
        setConfirmLoading(false);
        setShowConfirmModal(false);
      }
    });
    setShowConfirmModal(true);
  };

  const handleExport = () => {
    let excelData = data.map(({ srno, btn, Status, ...rest }) => rest);
    exportToExcel(excelData, "employee_list");
  };
  const handleRefresh = async () => {
    setRefreshLoading(true); // Enable refresh loader before the async call
    console.log(refreshLoading);
    try {
      await getUserListAccordingToRoleFun(); // Ensure this is awaited
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false); // Disable refresh loader after completion
    }
  };

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Employee Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        {/* {isUserListLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <DataTable columns={column} data={data} />
            </Row>
          </>
        )} */}

        {isUserListLoading ? (
          <Loader />
        ) : (
          <>
            {userListAccordingToRole?.users?.length > 0 ? (
              <>
                <ExportButton onClick={handleExport} />
                <Row>
                  <DataTable columns={column} data={data} />
                </Row>
              </>
            ) : (
              <Row>
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-center mt-5 fw-semibold"
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
      <ConfirmModal
        show={showConfirmModal}
        title={confirmTitle}
        onConfirm={confirmAction}
        onHide={() => setShowConfirmModal(false)}
        loading={confirmLoading}
      />
    </>
  );
}
