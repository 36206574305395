import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import DataTable from "../../../Globalcomponent/DataTable/DataTable";
import { useSelector } from "react-redux";
import Utility from "../../../utils/Utility";
import Loader from "./../../../components/Loader/Loader";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddMaintenanceModal from "./components/AddMaintenanceModal";
import "./Maintenance.css";
import { UserController } from "../../../Redux/controllers/UserController";
import BtnLoader from "../../../components/Loader/BtnLoader"; // Import the button loader

export default function Maintenance() {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false); // New state for refresh loading
  const [showAddMaintenanceModal, setShowAddMaintenanceModal] = useState(false);

  const { token, user } = useSelector((state) => state.user);
  const societyId = user?.societyDetails?.society?._id;

  const toggleAddMaintenanceModal = () => {
    setShowAddMaintenanceModal(!showAddMaintenanceModal);
  };

  const getMaintenanceData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getSocietyAdminMaintenanceDetails(
        token,
        pagination.pageIndex + 1,
        pagination.pageSize,
        societyId
      );
      setMaintenanceData(data?.data?.maintenanceRecords || []);
      setTotalPages(data?.data?.paginationInfo?.totalPages || 1);
      setRowCount(data?.data?.paginationInfo?.totalDocs || 1);
    } catch (error) {
      if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast(
          "Unable to get maintenance details! Please try again later."
        );
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token, pagination.pageIndex, pagination.pageSize, societyId]);

  const handleApprove = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await UserController.approveMaintenance(token, id);
        Utility.sToast("Maintenance approved successfully!");
        getMaintenanceData();
      } catch (error) {
        Utility.eToast(
          "Unable to approve maintenance! Please try again later."
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [token, getMaintenanceData]
  );

  const handleRefresh = async () => {
    setRefreshLoading(true); // Set the refresh loader to true
    try {
      await getMaintenanceData(); // Call the data fetching function
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false); // Set the refresh loader back to false
    }
  };

  useEffect(() => {
    if (societyId) {
      getMaintenanceData();
    }
  }, [pagination, societyId, getMaintenanceData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sino",
        header: <span className="custom-header">Sl No.</span>,
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 50,
      },
      {
        accessorKey: "apartmentname",
        header: "Apartment Name",
        size: 50,
      },
      {
        accessorKey: "flatno",
        header: "Flat No.",
        size: 50,
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 50,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 50,
      },
      {
        accessorKey: "isApproved",
        header: "Approval",
        size: 50,
        cell: ({ row }) => {
          const { id, isApproved } = row.original;
          return isApproved ? (
            <Button variant="success" disabled>
              Approved
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleApprove(id)}>
              Approve
            </Button>
          );
        },
      },
    ],
    [handleApprove]
  );

  const data = useMemo(
    () =>
      maintenanceData?.map((item, i) => ({
        id: item?.id,
        sino: (
          <span className="srnooo">
            {pagination.pageIndex * pagination.pageSize + i + 1}
          </span>
        ),
        name: (
          <span className="nameeee">
            {item?.headOfFamily?.firstName + " " + item?.headOfFamily?.lastName}
          </span>
        ),
        apartmentname: (
          <div className="d-flex">
            <Button disabled className="v-button">
              {item?.society?.societyName || "N/A"}
            </Button>
          </div>
        ),
        flatno: (
          <div className="d-flex">
            {item?.apartmentNumber ? (
              <Button disabled className="v-button">
                {item?.apartmentNumber}
              </Button>
            ) : (
              "N/A"
            )}
          </div>
        ),
        date: (
          <span className="nameeee">
            {Utility.formatDate(item?.createdAt, false)}
          </span>
        ),
        amount: (
          <span className="nameeee">
            {Utility.formatMoney(item?.maintenanceAmount)}
          </span>
        ),
        isApproved: item?.isApproved
          ? item?.paidOn !== null ? (
              <Button className="maint-btn" disabled>
                Paid
              </Button>
            ) : (
              <Button className="maint-btn" disabled>
                Approved
              </Button>
            )
          : (
            <Button disabled className="maint-btn success">
              Not Approved
            </Button>
          ),
      })),
    [maintenanceData, pagination.pageIndex, pagination.pageSize]
  );

  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Maintenance Payment Management
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        <Row className="add-maintenance-row">
          <Col md={5} xs={6} className="add-maintenance-col">
            <Button id="add-maintenance-btn" onClick={toggleAddMaintenanceModal}>
              <AiOutlinePlusCircle id="add-maintenance-icon" /> Add Maintenance
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: "1rem"}}>
          <Col style={{fontSize: "1.2rem", color: "gray"}}>Recent Maintenance</Col>
          <hr />
        </Row>
        <Row>
          {loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              data={data}
              enableCustomPagination={true}
              rowCount={rowCount}
              pagination={pagination}
              setPagination={setPagination}
              pageCount={totalPages}
            />
          )}
        </Row>
      </Container>

      {showAddMaintenanceModal && (
        <AddMaintenanceModal
          show={showAddMaintenanceModal}
          handleClose={toggleAddMaintenanceModal}
          getMaintenanceData={getMaintenanceData}
          token={token}
          societyId={societyId}
        />
      )}
    </>
  );
}
