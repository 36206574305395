import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";

const initialState = {
  // society cases
  isGetSocietyLoading: false,
  isAddSocietyLoading: false,
  isDeleteSocietyLoading: false,
};

// society CRUD
export const getSociety = createAsyncThunk(
  "getSociety",
  async (datas, { rejectWithValue }) => {
    try {
      const response = await UserController.getSociety(
        datas?.data,
        datas?.token
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSociety = createAsyncThunk(
  "addSociety",
  async (datas, { rejectWithValue }) => {
    try {
      const response = await UserController.addSociety(
        datas?.data,
        datas?.token
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//   export const deleteSociety = createAsyncThunk(
//     "deleteSociety",
//     async (datas, { rejectWithValue }) => {
//       try {
//         const response = await UserController.deleteSociety(
//           datas?.data,
//           datas?.token
//         );
//         return response?.data;
//       } catch (error) {
//         return rejectWithValue(error);
//       }
//     }
//   );

const societySlice = createSlice({
  name: "society",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get society case
    builder.addCase(getSociety.pending, (state, action) => {
      state.isGetSocietyLoading = true;
    });
    builder.addCase(getSociety.fulfilled, (state, action) => {
      state.isGetSocietyLoading = false;
      console.log("getSociety response", action.payload);
    });
    builder.addCase(getSociety.rejected, (state, action) => {
      state.isGetSocietyLoading = false;
    });

    // add society case
    builder.addCase(addSociety.pending, (state, action) => {
      state.isAddSocietyLoading = true;
    });
    builder.addCase(addSociety.fulfilled, (state, action) => {
      state.isAddSocietyLoading = false;
      console.log("addSociety response", action.payload);
    });
    builder.addCase(addSociety.rejected, (state, action) => {
      state.isAddSocietyLoading = false;
    });
  },
});

// export const { logout, setToken } = societySlice.actions;
export default societySlice.reducer;
