import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import BarChat from "./Components/BarChat/BarChat";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import { CiFilter } from "react-icons/ci";
import "./DashboardPage.css";
import CardComp from "../../../Globalcomponent/Card/CardComp";
import { useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import Loader from "./../../../components/Loader/Loader";
import Utility from "./../../../utils/Utility";
import BtnLoader from "../../../components/Loader/BtnLoader"; // Import BtnLoader

export default function DashboardPage() {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [maintenanceLoading, setMaintenanceLoading] = useState(false);
  const [latestNotice, setLatestNotice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false); // Refresh loader state
  const { token } = useSelector((st) => st?.user);

  const getMaintenancePaymentHistoryDetails = async () => {
    try {
      setMaintenanceLoading(true);
      const { data } = await UserController.getMaintenanceDetails(token);
      let allData = data?.data?.maintenanceRecords || [];
      let paidOnDetails = allData
        .filter((item) => item?.paidOn)
        .sort((a, b) => new Date(b.paidOn) - new Date(a.paidOn))
        .slice(0, 10);
      setMaintenanceData(paidOnDetails);
    } catch (error) {
    } finally {
      setMaintenanceLoading(false);
    }
  };

  const getLatestNotice = async () => {
    try {
      setIsLoading(true);
      const { data } = await UserController.getSocietyAdminNotices(token, 1, 1);
      const latest = data?.data?.notices?.[0];
      setLatestNotice(latest);
    } catch (error) {
      Utility.eToast("Unable to get notices! Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true); // Enable refresh loader
    try {
      await getMaintenancePaymentHistoryDetails();
      await getLatestNotice();
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false); // Disable refresh loader
    }
  };

  useEffect(() => {
    getMaintenancePaymentHistoryDetails();
    getLatestNotice();
  }, []);

  return (
    <>
      <Container fluid style={{ marginLeft: "0", paddingLeft: "0" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Dashboard
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
        </Row>
        <Row>
          <Col lg={6} xs={12} className="barchart-container">
            <Row className="visitor-roww">
              <Col md={6} xs={6} className="visitor-coll">Visitor Count</Col>
              <Col md={6} xs={6} className="visitor-coll2">
                <CiFilter className="filterrrr" /> &nbsp;&nbsp; Filter
              </Col>
            </Row>
            <Row>
              <BarChat />
            </Row>
          </Col>
          <Col lg={6} xs={12} className="dash-table-container">
            <Row>
              <Col className="maintenance">Maintenance Payment History</Col>
            </Row>
            <Row className="table-header">
              <Col xs={4} className="Nameeee">Name</Col>
              <Col xs={5} className="Nameeee">Apartment & Flat No.</Col>
              <Col xs={3} className="Nameeee">Amount</Col>
            </Row>
            {maintenanceLoading ? (
              <Container className="loader-container">
                <Loader className="maintenance-loader" />
              </Container>
            ) : (
              maintenanceData?.slice(0, 10)?.map((item) => (
                <Row className="maintainance-row" key={item?._id}>
                  <Col xs={4} className="Nameeee123">
                    {item?.headOfFamily?.firstName +
                      " " +
                      item?.headOfFamily?.lastName}
                  </Col>
                  <Col xs={5} className="Nameeee123">
                    {item?.society?.societyName && item?.apartmentNumber
                      ? `${item?.society?.societyName}, ${item?.apartmentNumber}`
                      : "N/A"}
                  </Col>
                  <Col xs={3} className="Nameeee123">
                    {item?.maintenanceAmount
                      ? Utility.formatMoney(item?.maintenanceAmount)
                      : "N/A"}
                  </Col>
                </Row>
              ))
            )}
          </Col>
        </Row>
        <Row className="recent-row">
          <Col className="recent-complaints">Recent Notice / Notifications</Col>
        </Row>
        <Row>
          {isLoading ? <Loader /> : latestNotice && (
            <CardComp
              content={latestNotice?.content}
              date={latestNotice?.createdAt}
              title={latestNotice?.title}
              noticeId={latestNotice?._id}
              deleted={() => getLatestNotice()}
            />
          )}
        </Row>
      </Container>
    </>
  );
}