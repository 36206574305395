import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import EmergencySystemCard from "../../../Globalcomponent/EmergencySystemCard/EmergencySystemCard";

export default function EmergencySystem() {
  const data = [1, 2, 3];
  return (
    <>
      <Container style={{ marginLeft: "-0.7rem" }}>
        <Row style={{ marginTop: "1.5rem" }}>
          <TopBar name="Emergency Notification" />
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col style={{ fontSize: "1.2rem", color: "gray" }}>
            Recent Emergency Alerts
          </Col>
          <hr />
        </Row>
        <Row>
          {data.map((item) => (
            <EmergencySystemCard key={item} />
          ))}
        </Row>
      </Container>
    </>
  );
}
