import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import TopBar from "../../../Globalcomponent/TopBar/TopBar";
import "./DashboardPage.css";
import { useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import Loader from "./../../../components/Loader/Loader";
import Utility from "./../../../utils/Utility";
import {
  GroupedBarChart,
  SocietyStatsBarChart,
} from "./Components/BarChat/BarChat";
import {
  SubAdminsPieChart,
  EmployeeSubRoleDoughnutChart,
} from "./Components/PieChart/PieChart";
import BtnLoader from "../../../components/Loader/BtnLoader";

export default function DashboardPage() {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [maintenanceLoading, setMaintenanceLoading] = useState(false);
  const [latestNotice, setLatestNotice] = useState(null); // State to store the latest notice
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const [societyList, setSocietyList] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [societyListLoading, setSocietyListLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const getSocietyList = async () => {
    setSocietyListLoading(true);
    try {
      const response = await UserController.getSociety(token);
      setSocietyList(response?.data?.data || []);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setSocietyListLoading(false);
    }
  };

  const getStatisticsData = async (societyId = null) => {
    setStatsLoading(true);
    try {
      const res = await UserController.getStatsData(token, societyId);
      setStatsData(res?.data?.data || []);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setStatsLoading(false);
    }
  };

  const handleSelect = (eventKey) => {
    setSelectedSociety(
      eventKey === "all"
        ? null
        : societyList.find((society) => society._id === eventKey)
    );
    getStatisticsData(eventKey === "all" ? null : eventKey);
  };

  const getMaintenancePaymentHistoryDetails = async () => {
    setMaintenanceLoading(true);
    try {
      const { data } = await UserController.getMaintenanceDetails(token);
      let allData = data?.data?.maintenanceRecords || [];
      let paidOnDetails = allData
        .filter((item) => item?.paidOn)
        .sort((a, b) => new Date(b.paidOn) - new Date(a.paidOn))
        .slice(0, 8);
      setMaintenanceData(paidOnDetails);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setMaintenanceLoading(false);
    }
  };

  const getLatestNotice = async () => {
    try {
      setIsLoading(true);
      const { data } = await UserController.getSocietyAdminNotices(token, 1, 1); // Fetch only the most recent notice
      const latest = data?.data?.notices?.[0];
      setLatestNotice(latest);
    } catch (error) {
      if (error?.message === "Network Error") {
        Utility.eToast("Network error! Please try again later.");
      } else {
        Utility.eToast("Unable to get notices! Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSocietyList();
    getStatisticsData();
    getMaintenancePaymentHistoryDetails();
    getLatestNotice();
  }, []);

const handleRefresh = async () => {
  setRefreshLoading(true); // Enable refresh loader
  try {
    await getSocietyList();
    await getStatisticsData();
    await getMaintenancePaymentHistoryDetails();
    await getLatestNotice();
  } catch (error) {
    console.error(error);
  } finally {
    setRefreshLoading(false); // Disable refresh loader
  }
};

  return (
    <Container style={{ marginLeft: "" }} className="pb-5">
      <Row style={{ margin: "1.5rem 0rem" }}>
      <TopBar
            name={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <h1 style={{ marginBottom: "0px", marginRight: "15px" }}>
                  Dashboard
                </h1>
                <Button
                  style={{
                    padding: "5px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "19px",
                        padding: "0px",
                        borderRadius: "15px",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "1rem",
                          marginBottom: "0px",
                          marginRight: "5px",
                        }}
                      >
                        Refreshing{" "}
                      </h6>
                      <BtnLoader />
                    </div>
                  ) : (
                    <h6 style={{ marginBottom: "0px", marginRight: "0px" }}>
                      Refresh
                    </h6>
                  )}
                </Button>
              </div>
            }
          />
      </Row>

      <Row>
        <Col xs={12} className="d-flex justify-content-end">
          <DropdownButton
            align="start"
            title={selectedSociety ? selectedSociety.societyName : "All Stats"}
            onSelect={handleSelect}
            id="stats-dropdown"
            disabled={societyListLoading}
          >
            <Dropdown.Item eventKey="all">All Stats</Dropdown.Item>
            {societyList?.map((item) => (
              <Dropdown.Item key={item?._id} eventKey={item?._id}>
                {item?.societyName}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>

      {/* Bar Chart */}
      <Row>
        <Col xs={12} id="full-bar-chart">
          {statsLoading ? (
            <Loader />
          ) : (
            <SocietyStatsBarChart data={statsData} />
          )}
        </Col>
      </Row>

      {/* Pie Chart and Maintenance Data */}
      <Row className="mt-1">
        <Col xs={12} md={6} id="pie-chart-col">
          <div className="graph-heading">Employee Statistics</div>
          {statsLoading ? (
            <Loader />
          ) : (
            <EmployeeSubRoleDoughnutChart
              width={400}
              height={400}
              data={statsData?.employeesBySubRole || {}}
            />
          )}
        </Col>

        {/* Maintenance Data */}
        <Col md={6} xs={12} id="barchat-row2">
          <div className="graph-heading">Maintenance Payment History</div>

          <Row className="mt-3">
            <Col xs={4} className="Nameeee">
              Name
            </Col>
            <Col xs={5} className="Nameeee">
              Apartment & Flat No.
            </Col>
            <Col xs={3} className="Nameeee">
              Amount
            </Col>
            <hr />
          </Row>

          <div className="">
            {maintenanceLoading ? (
              <Container className="loader-container">
                <Loader className="maintenance-loader" />
              </Container>
            ) : (
              <>
                {maintenanceData?.slice(0, 10)?.map((item) => (
                  <Row className="maintainance-row" key={item?._id}>
                    <Col xs={4} className="Nameeee123">
                      {item?.headOfFamily?.firstName +
                        " " +
                        item?.headOfFamily?.lastName}
                    </Col>
                    <Col xs={5} className="Nameeee123">
                      {item?.society?.societyName && item?.apartmentNumber
                        ? `${item?.society?.societyName}, ${item?.apartmentNumber}`
                        : "N/A"}
                    </Col>
                    <Col xs={3} className="Nameeee123">
                      {item?.maintenanceAmount
                        ? Utility.formatMoney(item?.maintenanceAmount)
                        : "N/A"}
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* Bar Chart and Pie Chart */}
      <Row className="mt-4">
        <Col xs={12} md={6} id="pie-chart-col">
          <div className="graph-heading">Profile Approval Statistics</div>
          {statsLoading ? (
            <Loader />
          ) : (
            <GroupedBarChart data={statsData} width={500} height={400} />
          )}
        </Col>
        <Col xs={12} md={6} id="pie-chart-col-last">
          <div className="graph-heading">Sub Admin Statistics</div>
          {statsLoading ? (
            <Loader />
          ) : (
            <SubAdminsPieChart
              data={statsData?.subadminsBySubRole || {}}
              width={300}
              height={300}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
