import React, { useState } from "react";
import { Modal, Form, Button, Col, Row, Spinner } from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { UserController } from "../../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "../../../../utils/Utility";

const LegalModal = (props) => {
  const [title, setTitle] = useState(
    props?.data?.title ? props?.data?.title : ""
  );
  const [content, setContent] = useState(
    props?.data?.content ? props?.data?.content : ""
  );
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);

  const handleReset = () => {
    setContent("");
    setTitle("");
  };
  const handleClose = () => {
    if (props.edit) {
      setTitle(props?.data?.title);
      setContent(props?.data?.content);
    }
    props.onHide();
  };

  const handleSave = async () => {
    if (!title) {
      return Utility.eToast("Title is required");
    }
    if (!content) {
      return Utility.eToast("Description is required");
    }
    try {
      setLoading(true);
      const response = await UserController.addLegal(token, {
        title,
        type: props.type,
        content,
      });
      Utility?.sToast(response?.data?.message);
      props.isChanged((prev) => !prev);
      handleClose();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = async () => {
    if (!title) {
      return Utility.eToast("Title is required");
    }
    if (!content) {
      return Utility.eToast("Description is required");
    }
    try {
      setLoading(true);
      const response = await UserController.editLegal(token, props?.data?._id, {
        title,
        type: props?.data?.type,
        content,
      });
      Utility?.sToast(response?.data?.message);
      props.isChanged((prev) => !prev);

      handleClose();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal size="lg" {...props} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#1A7857" }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label id="title-label">Title</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              id="null-placeholder"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label id="title-label">Description</Form.Label>
            <ReactQuill
              theme="snow"
              id="null-placeholder"
              value={content}
              onChange={(val) => setContent(val)}
            />
          </Form.Group>
        </Form>
        <Row className="mt-5  ">
          <Col
            xs={6}
            className="d-flex align-items-center justify-content-end "
          >
            <Button id="close-button" onClick={handleReset}>
              Reset
            </Button>
          </Col>
          <Col xs={6}>
            {loading ? (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <Button
                id="close-button1"
                onClick={props.edit ? handleEdit : handleSave}
              >
                {props?.edit ? "Update" : "Submit"}
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LegalModal;
