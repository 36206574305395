import { Col, Pagination, Row } from "react-bootstrap";

export default function PaginationComp({
  totalPages,
  activePage,
  setActivePage,
}) {
  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  const handlePrevious = () => {
    try {
      if (activePage > 1) {
        setActivePage((prev) => prev - 1);
      }
    } catch (error) {}
  };
  const handleNext = () => {
    try {
      if (activePage < totalPages) {
        setActivePage((prev) => prev + 1);
      }
    } catch (error) {}
  };
  return (
    <Row>
      <Col
        xs={12}
        className="d-flex align-items-center justify-content-end my-5"
      >
        <Pagination size="md" className="">
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={handlePrevious}
          />

          {items}
          <Pagination.Next
            disabled={activePage === totalPages}
            onClick={handleNext}
          />
        </Pagination>
      </Col>
    </Row>
  );
}
